import React, {useContext, useEffect, useRef} from 'react';

/*Styles*/
import * as S from './styles/general_nav_pages_styles';

/*Created components*/
import useHoverAction from "@current/main/functions/stylistic/useHoverAction";
import SliderContext from "@core/components/slider/context";



const SecondNavPage = ({isExistPopupAsk, setIsExistPopupAsk, page, setPage}) => {
    const {dispatch} = useContext(SliderContext)

    /**Change extreme button style**/
    const wrapperSecondNavPage = useRef(null)
    const wrapperIconBlockRef = useRef(null)
    const isReadyToChange = useHoverAction(wrapperIconBlockRef, false);

    /*decrease*/
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (!wrapperSecondNavPage.current.contains(e.target) && page === "SecondPage" && !isExistPopupAsk) {
                slideToFirstPage();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [page, isExistPopupAsk]);

    const slideToFirstPage = () => {
        setPage("FistPage");
        dispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };



    return (
        <S.NavPage ref={wrapperSecondNavPage}>
            <S.ExtremeRightNavItemBlock__ScrollToLeft $scrollTo={'left'} ref={wrapperIconBlockRef} onClick={slideToFirstPage}>
                <S.ExtremeNavItemIcon__ScrollToLeft $scrollTo={'left'}/>
                <S.ExtremeNavItemText__ScrollToLeft $scrollTo={'left'} $isReadyToChange={isReadyToChange}>Вернуться</S.ExtremeNavItemText__ScrollToLeft>
            </S.ExtremeRightNavItemBlock__ScrollToLeft>
            <S.NavItem to={'/references/payment/base'}>Оплата и доставка</S.NavItem>
            <S.NavItem to={'/references/cooperation/base'}>Сотрудничество</S.NavItem>
            <S.NavItem to={'/news'}>Новости</S.NavItem>
            <S.NavItem to={'/articles'}>Статьи</S.NavItem>
            <S.NavItem to={'/references'}>Справка</S.NavItem>
            <S.NavItem as={'div'} className={'question'} onClick={()=>{setIsExistPopupAsk(true)}}>Задать вопрос</S.NavItem>
        </S.NavPage>
    );
};

export default SecondNavPage;