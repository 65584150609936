import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useRequest from "@core/api/hooks/useRequest";
import {addNotification} from "@redux/redux_reducers/notify_message_slice";
import {UserInfoSetCity} from "@redux/redux_reducers/user_info";



const useCity = () => {
    const dispatch = useDispatch();
    const { baseItems } = useLoaderData();
    const [city, setCity] = useState(null);
    const [cities, setCities] = useState({});
    const { executeRequest, successData } = useRequest();

    useEffect(() => {
        if (!localStorage.getItem('user_city')) {
            setCity(baseItems.data.city);
            localStorage.setItem('user_city', baseItems.data.city);
            dispatch(UserInfoSetCity(baseItems.data.city));
            dispatch(addNotification("Ваш город определен как: " + baseItems.data.city));
        }
        else {
            const recent_city = localStorage.getItem('user_city')
            setCity(recent_city);
            dispatch(UserInfoSetCity(recent_city));
        }
    }, [baseItems, dispatch]);

    useEffect(() => {
        if (successData) {
            setCities(successData.cities);
        }
    }, [successData]);

    const fetchCities = async () => {
        const apiUrl = `api/get_cities/${city}`;
        await executeRequest('get', apiUrl);
    };

    const chooseCity = (event) => {
        const newCity = event.target.textContent;
        setCity(newCity);
        localStorage.setItem('user_city', newCity);
        dispatch(UserInfoSetCity(baseItems.data.city));
        dispatch(addNotification("Ваш город изменен на: " + newCity));
    };

    return { city, cities, fetchCities, chooseCity };
};

export default useCity;