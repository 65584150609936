import styled from 'styled-components';
import {
    GeneralDefaultInputCSS
} from "@core/components/form_slider_components/general_popup/input_components/input/styles/input_styles";
import {WithoutUserPointers} from "@core/css/css_layouts";



export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const InvisibleInput = styled.input.attrs({ type: "file" })`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`

export const FieldFileWrapper = styled.label`
  width: 100%;
  display: flex;
`

export const FieldFileFake = styled.div`
  ${GeneralDefaultInputCSS};
  font-size: 0.65em;
  height: 2em;
  flex-basis:0;
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: none
`

export const FieldFileButton = styled.div`
  ${GeneralDefaultInputCSS};
  font-size: 0.65em;
  height: 2em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis:0;
  flex-grow: 1;
  cursor: pointer;
  
  &:hover{
    background: var(--STANDARD);
    color: white;
  }
`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`