import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/product_page_styles';

/*Created components*/
import ApiRequest from "@core/api/api_request";
import {useLoaderData} from "react-router-dom";
import SubproductBlock from "@components/water_site_base/subproduct_block/subproduct_block";



export async function SuproductsLoader({ params }) {
    const { productSlug } = params;
    const suproductsItems = await ApiRequest("get", `api/loaders/products/${productSlug}`);
    return {suproductsItems};
}


const ProductPage = () => {
    const {suproductsItems} = useLoaderData();
    const [subproducts, setSubproducts] = useState([])

    useEffect(()=>{
        if (suproductsItems && suproductsItems.data){
            console.log(suproductsItems.data.subproducts)
            setSubproducts(suproductsItems.data.subproducts)
        }
    },[suproductsItems])

    return (
        <S.ProductPage>
            {subproducts?.length > 0 && (
                subproducts.map((item) => (
                    <SubproductBlock key={item.id} subproduct={item}/>
                ))
            )}
        </S.ProductPage>
    );
};

export default ProductPage;
