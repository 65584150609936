import styled from 'styled-components';
import {StandardButtonBold} from "@current/main/stylistic/standard_button";



export const a1 = styled.div`

`

export const a2 = styled.div`

`

export const a3 = styled.div`

`

/**Choose model form**/
export const ButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ButtonsBlockInside = styled.div`
  box-sizing: border-box;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.25rem;
`

export const ButtonChooseButton = styled(StandardButtonBold)`
  font-size: 0.8rem;
  margin-top: 0.75rem;
  padding: 0.25rem 0.45rem;
`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`