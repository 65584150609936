import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/visualization_block_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import ModelBlock from "@components_startapp/model_block/model_block";
import {ModelProvider} from "@current/main/functions/3D/model_provider";



const VisualizationBlock = () => {
    return (
        <S.VisualizationBlock id='visualization'>
            <S.Wrapper>
                <ComponentsHeaderBlock headerText={"Визуализация"}
                                       additionalText={"Создавайте визуализации из собственных макетов, используя модели на плафторме."}/>

                <S.ModelWrapper>
                    <ModelProvider>
                        <ModelBlock/>
                    </ModelProvider>
                </S.ModelWrapper>
            </S.Wrapper>
        </S.VisualizationBlock>
    );
};

export default VisualizationBlock;