import React, {useContext} from 'react';
import {useDispatch} from "react-redux";

/*Styles*/
import {slideUpDown} from '@core/components/form_slider_components/general_popup/motion_animations/animations';

/*Created components*/
import AuthContext from "@entry_point/initalizers/auth_context";
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import PasswordInput from '@core/components/form_slider_components/general_popup/input_components/password_input';
import useFormData from '@core/hooks/useFormData';
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import MotionDiv from "@core/components/motion_div/motion_div";
import {setTextAndHeaderOkPopup} from "@src/current/entry_point/redux/redux_reducers/ok_popup_slice";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const ChangePasswordAddNewPassword = () => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const {activePageName, userContact,} = useContext(PopupInsideContext);
    const {setIsAuthenticated} = useContext(AuthContext)
    const dispatch = useDispatch();
    /*Extra states*/
    let noChangeActivePages = ['Entry', 'ChangePasswordAddNewPassword'];

    /**Data logic**/
    const {formData, handleChange} = useFormData({
        contact_info_type: userContact.contact_info_type,
        contact_info: userContact.contact_info,
        new_password_1: '',
        new_password_2: '',
    });

    const successFunction = (successData) =>{
        localStorage.setItem('access_token', successData.tokens.access);
        localStorage.setItem('refresh_token', successData.tokens.refresh);
        closePopupFunc();
        setIsAuthenticated(true);
        dispatch(setTextAndHeaderOkPopup({text:"Вы успешно сменили пароль!", header: ""}))
    }

    return (
        <PageWrapper>
            <PageHeader>Смена пароля</PageHeader>

            <InputsForm requestUrl={"api/users/change_password/final/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                <PasswordInput name='new_password_1'/>

                {noChangeActivePages.includes(activePageName) && (
                    <MotionDiv initial={'usualInitial'}
                                      animate='animate'
                                      exit='exit'
                                      variants={slideUpDown()}>

                        <PasswordInput name='new_password_2'/>
                    </MotionDiv>
                )}

                <PageButtons__Wrapper margin={'big'}>
                    <PageButton>Редактировать</PageButton>
                </PageButtons__Wrapper>
            </InputsForm>
        </PageWrapper>
    );
};

export default ChangePasswordAddNewPassword;
