import styled from 'styled-components';
import Icons from "@values/icons";
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const CartButton = styled.div`
  position: relative;
  height: 1.35rem;
  padding: 0.15rem 0.4rem 0.15rem 0.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background:
          linear-gradient(white 0 0) padding-box,
          linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  border:  0.1rem solid transparent;
  border-radius: 1.2rem;
  ${WithoutBlueHighlighting};

  &::before {
    position: absolute;
    border-radius: 1.2rem;
    content: "";
    inset: 0;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
    opacity: 0;
    transition: 0.25s all ease;
    transition-delay: 0.025s;
  }

  &:hover::before{
    position: absolute;
    opacity: 1;
  }

  /*175%*/
  @media (max-width: 1200px) {
    display: none;
  }
`

export const CartIcon = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  background: url(${Icons.CartInSquare}) no-repeat center;
  background-size: 75% 75%;
  filter: ${(props) => (props.$isReadyToChange ? 'invert(100%)': 'invert(0%)')};
  transition: 0.25s all ease;
  transition-delay: 0.025s;
`

export const Text = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  z-index: 2;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  color: ${(props) => (props.$isReadyToChange ? 'white': 'black')};
`

export const CartIconMobile = styled.div`
  background: url(${Icons.CartInSquareWS}) no-repeat center;
  background-size: contain;
  height: 1.65rem;
  width: 1.65rem;
  padding: 0.25rem;
`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`