import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/base_header_styles';

/*Created components*/
import HeaderLogoAndButtonsLine from "@components_startapp/base_header/header_logo_and_buttons_line/header_logo_and_buttons_line";
import Navigation from "@components_startapp/base_header/navigation/navigation";



const BaseHeader = () => {

    return (
        <S.BaseHeader id='top'>
            <HeaderLogoAndButtonsLine/>
            <Navigation/>
        </S.BaseHeader>
    );
};

export default BaseHeader;