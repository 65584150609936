import React, {useState} from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/navigation_styles';

/*Created components*/
import Slider from "@core/components/slider/slider";
import FirstNavPage from "@components/water_site_base/base_header/navigation/pages/first_nav_page";
import SecondNavPage from "@components/water_site_base/base_header/navigation/pages/second_nav_page";



const Navigation = ({isExistPopupAsk, setIsExistPopupAsk}) => {
    const [activePage, setActivePage] = useState(null)

    return (
        <S.Navigation>
            <Slider>
                <Slider.Slide>
                    <FirstNavPage setPage={setActivePage}/>
                </Slider.Slide>

                <Slider.Slide>
                    <SecondNavPage isExistPopupAsk={isExistPopupAsk} setIsExistPopupAsk={setIsExistPopupAsk} page={activePage} setPage={setActivePage}/>
                </Slider.Slide>
            </Slider>
        </S.Navigation>
    );
};

export default Navigation;