import { configureStore } from '@reduxjs/toolkit'



/*Slices*/
import notifyMessageSlice from "@redux/redux_reducers/notify_message_slice"
import okPopupSlice from "@redux/redux_reducers/ok_popup_slice"
import yesNoPopupSlice from "@redux/redux_reducers/yes_no_popup_slice"
import mobileNavigationSlice from "@redux/redux_reducers/mobile_navigation"
import userInfoSlice from "@redux/redux_reducers/user_info"



const store = configureStore({
    reducer: {
        /**Popups**/
        NotifyMessageReducer: notifyMessageSlice,
        OkPopupSliceReducer: okPopupSlice,
        YesNoPopupReducer: yesNoPopupSlice,
        /**Stylistic**/
        MobileNavigationReducer: mobileNavigationSlice,
        /**User**/
        UserInfoReducer: userInfoSlice,
    },
})

export default store;