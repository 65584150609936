import React, {useRef} from 'react';

/*Styles*/
import * as S from './styles/product_button_block_styles';

/*Created components*/
import useHoverAction from "@current/main/functions/stylistic/useHoverAction";
import add_base_url from "@core/functions/add_base_url";
import {Link} from "react-router-dom";



const ProductButtonBlock = ({product}) => {
    /**Change color**/
    const wrapperProduct = useRef(null)
    const isReadyToChange = useHoverAction(wrapperProduct, false);

    return (
        <S.ProductButtonBlock as={Link} to={'products/' + product.slug} ref={wrapperProduct}>
            <S.ProductImage src={add_base_url(product.image)}/>
            <S.ProductName>{product.name}</S.ProductName>
            <S.ProductButton $isReadyToChange={isReadyToChange}>
                Посмотреть
            </S.ProductButton>
        </S.ProductButtonBlock>
    );
};

export default ProductButtonBlock;