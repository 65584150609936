import React from 'react';

/*Styles*/
import * as S from './styles/example_button_styles';



const LineBreaker = () => {
    return (
        <S.LineBreaker/>
    );
};

export default LineBreaker;