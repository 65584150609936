import styled from 'styled-components';



export const ComponentsHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Header = styled.div`
  font-size: 1.275rem;
  font-weight: 600;
  border: 0.175rem solid var(--BLUE_DARK);
  padding: 0.15rem 0.55rem;
  border-radius: 1.2rem;
`

export const AdditionalText = styled.div`
  margin-top: 0.35rem;
  font-size: 0.75rem;
  text-align: center;
  
  /*400%*/
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`