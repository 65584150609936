import $ from 'jquery';

export function handlerAnchorClick(e) {
    e.preventDefault();
    scrollToAnchor(e.currentTarget.getAttribute('href'));
}


export function scrollToAnchor(anchorId) {
    let correct_anchor_id;

    if (anchorId[0] === "/"){
        correct_anchor_id = anchorId.slice(1);
    }
    else{
        correct_anchor_id = anchorId;
    }

    let pxNormalize = -80;
    if (correct_anchor_id === "#top"){
        pxNormalize = -20
    }

    $("html, body").animate({
        scrollTop: $(correct_anchor_id).offset().top + pxNormalize
    },{
        duration: 800,easing: "swing"
    });
}

