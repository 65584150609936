import Play from "@assets/icons/model_icons/play.svg";
import Stop from "@assets/icons/model_icons/stop.svg";
import MakePhoto from "@assets/icons/model_icons/make_photo.svg"
import MakeVideo from "@assets/icons/model_icons/make_video.svg"



const ModelIcons = {
    Play,
    Stop,
    MakePhoto,
    MakeVideo
}

export default ModelIcons;