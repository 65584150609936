import React from 'react';
import {createBrowserRouter} from "react-router-dom";

/*Created components*/
import BaseTemplate, {baseData} from "@pages/water_site_base/base_template/base_template";
import MainPage, {mainPageLoader} from "@pages/water_site_base/main_page/main_page";
import ProductPage, {SuproductsLoader} from "@pages/product_page/product_page";



const router = createBrowserRouter([
    {
        loader: baseData,
        path: '/',
        element: (
            <BaseTemplate/>
        ),
        children: [
            {
                loader: mainPageLoader,
                path: '',
                element: <MainPage/>
            },
            {
                loader: SuproductsLoader,
                path: 'products/:productSlug',
                element: <ProductPage/>
            }
        ]
    },
]);

export default router;

