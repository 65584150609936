import React from 'react';

/*Styles*/
import * as S from './styles/general_nav_pages_styles';

/*Created components*/



const FirstNavPage = () => {

    return (
        <S.NavPage>
            <S.NavItem to={'/#about'}>О нас</S.NavItem>
            <S.NavItem to={'/#products'}>Продукция</S.NavItem>
            <S.NavItem to={'/#visualization'}>Визуализация</S.NavItem>
            <S.NavItem to={'/#design'}>Дизайн</S.NavItem>
            <S.NavItem to={'/#cooperation'}>Сотрудничество</S.NavItem>
            <S.NavItem to={'/#contacts'}>Контакты</S.NavItem>
        </S.NavPage>
    );
};

export default FirstNavPage;