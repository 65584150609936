import styled from 'styled-components';
import Test from "@values/test/test";
import Icons from "@values/icons";
import {StandardButtonBold} from "@current/main/stylistic/standard_button";


export const BaseFooter = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
  overflow: hidden;
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapperForPart = styled.div`
  flex-basis: 0;
  flex-grow: 1;

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`


/**Contacts**/
export const ContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ContactsHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  cursor: default;
`

export const ContactsPhone = styled.div`
  margin-top: 0.6rem;
`

export const ContactsEmail = styled.div`
  margin-top: 0.25rem;
`

/*Socials*/
export const SocialsBlock = styled.div`
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-start;
`

export const SocialIconBlock = styled.div`
  width: 2.25rem;
  aspect-ratio: 1/1;
  margin-right: 0.75rem;
  background: url(${Test.SocialTest});
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`


/**Fasie block**/
export const FasieBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const FasieHeader = styled.div`
  font-size: 1.05rem;
  font-weight: 600;

  &::after {
    content: "";
    display: block;
    margin-top: 0.15rem;
    width: 100%;
    height: 0.1rem;
    background: var(--BLUE_DARK);
  }
`

export const FasieIcon = styled.div`
  margin-top: 0.5rem;
  width: 6rem;
  height: 2.5rem;
  background: url(${Icons.FASIE}) no-repeat center;
  cursor: pointer;
`

export const FasieSeparator = styled.div`
  width: 100%;
  height: 0.1rem;
  background: var(--BLUE_DARK);
  margin-top: 0.5rem;
`

export const PutpIcon = styled.div`
  margin-top: 0.5rem;
  width: 7rem;
  height: 4.5rem;
  object-fit: contain;
  background: url(${Icons.PUTP}) no-repeat center;
  background-size: contain;
  cursor: pointer;
`


/**Follow block**/
export const FollowBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FollowHeader = styled.div`
  text-align: center;
  font-size: 0.85rem;
  font-weight: 600;
`

export const FollowButton = styled(StandardButtonBold)`
  margin-top: 0.5rem;
  display: inline;
`


/**Company info**/
export const CompanyInfo = styled.div`
  margin: 1.75rem 2rem 0 2rem;
  font-size: 0.55rem;
  display: flex;
  text-align: center;

  /**Phone**/
  /*500%*/
  @media (max-width: 400px) {
    font-size: 0.4rem;
  }
`


/**Additional urls block**/
export const AdditionalUrlsBlock = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 2rem;
  font-size: 0.6rem;
  background: var(--BLUE_2);
`

export const AdditionalUrlItem = styled.div`
  margin: 0 0.45rem;
  cursor: pointer;
`


/**Version block**/
export const VersionBlock = styled.div`
  margin-top: 1.5rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-size: 0.55rem;
`

export const VersionItem = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;

  &:nth-child(2) {
    justify-content: center;
  }
`
