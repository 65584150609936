import styled from 'styled-components';



export const ReferenceButton = styled.div`
  position: relative;
  width: 11rem;
  height: 5.5rem;
  background-image: linear-gradient(to top, var(--VIOLET_LIGHT), var(--VIOLET_DARK));
  margin: 0 0.4rem;
  border-radius: 0.4rem;
`

export const ReferenceType = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: #5065fd;
  border-radius: 1.2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: white;
`

export const ThemeText = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 2rem;
  font-size: 1.1rem;
  font-weight: 550;
`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`