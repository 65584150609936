import styled from 'styled-components';
import TypographyTest from "@assets/images/test/typography_test.png";
import Test from "@values/test/test";


export const NewsButton = styled.div`
  position: relative;
  width: 11rem;
  height: 5.5rem;
  background-image: linear-gradient(to top, var(--NEWS_BLUE_LIGHT), var(--NEWS_BLUE_DARK));
  margin: 0 0.4rem;
  border-radius: 0.4rem;
`

export const Date = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.3rem;
  border: 0.1rem solid black;
  color: black;
  border-radius: 1.2rem;
  font-size: 0.5rem;
  font-weight: 600;
`

export const MainInfoBlock = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const CompanyLogoBlock = styled.div`
  background: white;
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
`

export const CompanyLogo = styled.div`
  width: 3rem;
  height: 2rem;
  object-fit: contain;
  background: url(${Test.TypographyTest}) no-repeat center;
  background-size: contain;
  cursor: pointer;
`

export const ArticleText = styled.div`
  margin-top: 0.325rem;
  font-size: 1.05rem;
  line-height: 0.95rem;
  font-weight: 550;
`


export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`