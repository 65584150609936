import React from 'react';

/*Styles*/
import * as S from './styles/examples_for_main_page_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import ExampleButton from "@components/water_site_base/example_button/example_button";
import ComponentsSeeMoreButton from "@components/water_site_base/components_see_more_button/components_see_more_button";




const ExamplesForMainPage = () => {
    return (
        <S.ExamplesForMainPage>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Шаблоны"}
                />

                <S.ExamplesWrapper>
                    <ExampleButton/>
                    <ExampleButton/>
                    <ExampleButton/>
                    <ExampleButton.Break/>
                    <ExampleButton/>
                    <ExampleButton/>
                </S.ExamplesWrapper>

                <ComponentsSeeMoreButton/>
            </S.Wrapper>
        </S.ExamplesForMainPage>
    );
};

export default ExamplesForMainPage;