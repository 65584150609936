import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/mobile_navigation_styles';

/*Created components*/
import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion";
import {hideMobileMenu} from "@redux/redux_reducers/mobile_navigation";
import useCity from "@current/hooks/useCity";
import useClickOutside from "@core/hooks/useClickOutside";


const MobileNavigation = ({setIsExistPopupAsk}) => {
    const dispatch = useDispatch();
    const showed = useSelector((state) => state.MobileNavigationReducer.menuShowed);
    const [isMobileNav, setIsMobileNav] = useState(false)

    useEffect(() => {
        if (showed) {
            setIsMobileNav(true)
        } else {
            setIsMobileNav(false)
        }
    }, [showed])

    const handlerHideMenu = () =>{
        const hamburger = document.getElementsByClassName('ham')[0]
        hamburger.classList.remove('active');
        dispatch(hideMobileMenu());
    }

    /**City**/
    const {isDefault, setIsDefault, wrapperRef} = useClickOutside();
    const {city, cities, fetchCities, chooseCity} = useCity();

    const handleButtonClick = async () => {
        await fetchCities();
        setIsDefault(!isDefault);
    };

    const handleChooseCity = (event) => {
        chooseCity(event);
        setIsDefault(true);
    };

    return (
        <S.MobileNavigation>
            <AnimatePresence>
                {isMobileNav &&
                    <S.LeftNav ref={wrapperRef}
                               initial={{x: "-100%", opacity: 0}}
                               animate={{x: "0%", opacity: 1}}
                               exit={{x: "-100%", opacity: 0}}
                               transition={{
                                   duration: 0.5
                               }}>
                        <S.ScrollBlock>
                            <S.CitySelectorBlock $isDefault={isDefault} onClick={handleButtonClick}>{city}<S.CityArrow $isDefault={isDefault}/></S.CitySelectorBlock>
                            <AnimatePresence>
                                {!isDefault && (
                                    <S.ChooseCityBlock
                                        initial={{height: "0", opacity: 0}}
                                        animate={{height: "auto", opacity: 1 }}
                                        exit={{height: "0", opacity: 0}}
                                        transition={{
                                            duration: 0.25,
                                        }}>
                                        {cities?.length > 0 && (
                                            cities.map((item) => (
                                                <S.City key={item.id} onClick={handleChooseCity}>{item.name}</S.City>
                                            ))
                                        )}

                                    </S.ChooseCityBlock>
                                )}
                            </AnimatePresence>
                            <S.MenuItem className={'first'}>Главная</S.MenuItem>
                            <S.MenuItem>Продукция</S.MenuItem>
                            <S.MenuItem>Шаблонизатор</S.MenuItem>
                            <S.MenuItem>Визуализация</S.MenuItem>
                            <S.MenuItem>Примеры</S.MenuItem>
                            <S.MenuItem>Контакты</S.MenuItem>
                            <S.MenuItem>Оплата и доставка</S.MenuItem>
                            <S.MenuItem>Сотрудничество</S.MenuItem>
                            <S.MenuItem>Новости</S.MenuItem>
                            <S.MenuItem>Статьи</S.MenuItem>
                            <S.MenuItem>Справка</S.MenuItem>
                            <S.MenuItem onClick={()=>setIsExistPopupAsk(true)}>Задать вопрос</S.MenuItem>
                        </S.ScrollBlock>
                    </S.LeftNav>
                }
            </AnimatePresence>

            <AnimatePresence>
                {isMobileNav &&
                    <S.Background onClick={handlerHideMenu}
                        initial={{opacity: 0}}
                        animate={{opacity: 0.2}}
                        exit={{opacity: 0}}
                        transition={{
                            duration: 0.5
                        }}/>
                }
            </AnimatePresence>
        </S.MobileNavigation>
    );
};

export default MobileNavigation;
