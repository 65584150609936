import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

/*Styles*/
import * as S from './styles/product_finder_styles';
import {
    IconSquare
} from "@components/water_site_base/base_header/header_logo_and_buttons_line/styles/header_logo_and_buttons_line_styles";

import rawHTMLBurger
    from "@components/water_site_base/base_header/header_logo_and_buttons_line/burger_button/raw_html_burget_button";

/*Created components*/
import useHoverAction from "@current/main/functions/stylistic/useHoverAction";
import {hideMobileMenu, openMobileNav, showMobileMenu} from "@redux/redux_reducers/mobile_navigation";
import useClickOutside from "@core/hooks/useClickOutside";



const ProductFinder = () => {
    const dispatch = useDispatch();
    const showed = useSelector((state) => state.MobileNavigationReducer.menuShowed);
    const inputFinderRef = useRef(null);

    /**Change Icon color**/
    const wrapperIconBlockRef = useRef(null)
    const isReadyToChange = useHoverAction(wrapperIconBlockRef, false);

    /**Size changes*/
    const {isDefault, setIsDefault, wrapperRef} = useClickOutside();

    const expandHandler = () =>{
        setIsDefault(false);
        inputFinderRef.current.focus();
    }

    /**Show mobile menu**/
    const handlerShowHideMenu = () =>{
        const hamburger = document.getElementsByClassName('ham')[0]
        if (!showed){
            hamburger.classList.add('active');
            dispatch(openMobileNav());
        }
        else {
            hamburger.classList.remove('active');
            dispatch(hideMobileMenu());
        }
    }

    return (
        <>
            <S.ProductFinder ref={wrapperRef}>
                <S.LeftPart $isExpanded={!isDefault}  onClick={expandHandler}>
                    <S.FinderInput placeholder={isDefault ? "Поиск товаров" : ""} ref={inputFinderRef}/>
                </S.LeftPart>

                <S.RightPart ref={wrapperIconBlockRef}>
                    <S.FinderButton $isReadyToChange={isReadyToChange}/>
                </S.RightPart>
            </S.ProductFinder>

            <IconSquare onClick={handlerShowHideMenu}>
                <S.BurgerIcon dangerouslySetInnerHTML={{ __html: rawHTMLBurger }} />
            </IconSquare>
        </>
    );
};

export default ProductFinder;
