import React, {useRef} from 'react';

/*Styles*/
import * as S from './styles/file_input_styles';

/*Created components*/
import InputWrapper
    from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import InputHeader
    from "@core/components/form_slider_components/general_popup/input_components/input_header/input_header";
import Input from "@core/components/form_slider_components/general_popup/input_components/input/input";



const FileInput = ({headerText = "Поле", name, isExplanation = false, tooltipText, forPage}) => {
    const explanationRef = useRef(null);

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText} forPage={forPage} tooltipName={name}>
            <InputHeader isExplanation={isExplanation} explanationRef={explanationRef} headerText={headerText}/>

            <S.InputWrapper>
                <S.InvisibleInput name={name} id={`input_${name}`}/>

                <S.FieldFileWrapper htmlFor={`input_${name}`}>
                    <S.FieldFileFake>Файлы не выбраны</S.FieldFileFake>
                    <S.FieldFileButton>Выбрать</S.FieldFileButton>
                </S.FieldFileWrapper>
            </S.InputWrapper>
        </InputWrapper>
    );
};

export default FileInput;