import {useState, useEffect} from 'react';



function useHoverAction(ref, startStatus = false) {
    const [isReadyToChange, setIsReadyToChange] = useState(startStatus);

    const handleMouseOverColoring = () => {
        setIsReadyToChange(true);
    };

    const handleMouseOutClearColoring = () => {
        setIsReadyToChange(false);
    };

    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseover', handleMouseOverColoring);
            node.addEventListener('mouseout', handleMouseOutClearColoring);

            return () => {
                node.removeEventListener('mouseover', handleMouseOverColoring);
                node.removeEventListener('mouseout', handleMouseOutClearColoring);
            };
        }
    });
    return isReadyToChange;
}

export default useHoverAction;