import styled from 'styled-components';
import Icons from "@values/icons";
import {StandardButtonBold} from "@current/main/stylistic/standard_button";
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const BaseFooter = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
  overflow: hidden;
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapperForPart = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  ${WithoutBlueHighlighting}

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
  
  &.right{
    /*400%*/
    @media (max-width: 600px) {
      display: none;
    }
  }
`


/**Contacts**/
export const ContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ContactsHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  cursor: default;
`

export const ContactsPhone = styled.div`
  margin-top: 0.6rem;
`

export const ContactsEmail = styled.div`
  margin-top: 0.25rem;
`

/*Socials*/
export const SocialsBlock = styled.div`
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-start;
`

/**Fasie block**/
export const FasieBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${WithoutBlueHighlighting}
`

export const FasieHeader = styled.div`
  font-size: 1.05rem;
  font-weight: 600;

  &::after {
    content: "";
    display: block;
    margin-top: 0.15rem;
    width: 100%;
    height: 0.1rem;
    background: var(--BLUE_DARK);
  }
`

export const FasieIcon = styled.a`
  margin-top: 0.5rem;
  width: 6rem;
  height: 2.5rem;
  background: url(${Icons.FASIE}) no-repeat center;
  cursor: pointer;
`

export const FasieSeparator = styled.div`
  width: 100%;
  height: 0.1rem;
  background: var(--BLUE_DARK);
  margin-top: 0.5rem;
`

export const PutpIcon = styled.a`
  margin-top: 0.5rem;
  width: 7rem;
  height: 4.5rem;
  object-fit: contain;
  background: url(${Icons.PUTP}) no-repeat center;
  background-size: contain;
  cursor: pointer;
`


/**Follow block**/
export const FollowBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FollowHeader = styled.div`
  text-align: center;
  font-size: 0.85rem;
  font-weight: 600;
`

export const FollowButton = styled(StandardButtonBold)`
  margin-top: 0.5rem;
  display: inline;
`


/**Company info**/
export const CompanyInfo = styled.div`
  margin: 1.75rem 2rem 0 2rem;
  font-size: 0.575rem;
  display: flex;
  text-align: center;

  /*400%*/
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
`


/**Version block**/
export const VersionBlock = styled.div`
  margin-top: 1rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-size: 0.55rem;
  ${WithoutBlueHighlighting};

  /*400%*/
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
`

export const VersionItem = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;

  &:nth-child(2) {
    justify-content: center;
  }
`
