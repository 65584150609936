import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const Navigation = styled.div`
  padding-top: 0.4rem;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${WithoutBlueHighlighting};
`