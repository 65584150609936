import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const ProductSlider = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  ${WithoutBlueHighlighting};
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

/**STADANDARD**/
export const InsideWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  
  /*400%*/
  @media (max-width: 600px) {
    gap: 0.5rem;
  }
`

export const ProductsSliderWrapper_1 = styled(InsideWrapper)`
  margin-top: 1.5rem;

  /*400%*/
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`

export const ProductsSliderWrapper_2 = styled(InsideWrapper)`
  /*400%*/
  @media (max-width: 600px) {
    flex-direction: column;
  }
`


/**STADANDARD**/
export const StandardSideWrapper = styled.div`
  width: 25rem;

  /*175%*/
  @media (max-width: 1200px) {
    width: 24rem;
  }
  
  /*200%*/
  @media (max-width: 1000px) {
    width: 23rem;
  }

  
  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    width: 21rem;
  }

  /*300%*/
  @media (max-width: 700px) {
    width: 18rem;
  }

  /*400%*/
  @media (max-width: 600px) {
    width: 30rem;
  }
`

export const LeftWrapper = styled(StandardSideWrapper)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const RightWrapper = styled(StandardSideWrapper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;    
`

export const StandardImage = styled.img`
  width: 100%;
  border-radius: 0.4rem;
  box-shadow: 0 0.08rem 0.4rem #cacaca;
`

export const Header = styled.div`
  text-transform: uppercase;
  font-size: 1.65rem;
  font-weight: 600;
  user-select: none;
  pointer-events: none;
  
  /*400%*/
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`

export const SimpleText = styled.div`
  margin-top: 0.5rem;
  
  /*400%*/
  @media (max-width: 600px) {
    text-align: justify;
    hyphens: auto;
    font-size: 1rem;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

