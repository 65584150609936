import React, {useState} from 'react';

/*Styles*/
import * as S from './styles/subproduct_block_styles';

/*Created components*/
import add_base_url from "@core/functions/add_base_url";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import ModelBlock from "@components/water_site_base/model_block/model_block";
import {ModelProvider} from "@current/main/functions/3D/model_provider";
import addBrToString from "@core/functions/add_br_in_string";
import ModelLoadPopup from "@components/water_site_base/model_load_popup/model_load_popup";



const SubproductBlock = ({subproduct}) => {
    const model = subproduct.models[0]

    /**Popup**/
    /**Model popup**/
    const [isExistPopupModel, setIsExistPopupModel] = useState(false);
    const popupStatesPopupModel = {isExist: isExistPopupModel, setExist: setIsExistPopupModel, width: 'auto'};

    /**Model load popup**/
    const [isExistPopupModelLoad, setIsExistPopupModelLoad] = useState(false);
    const popupStatesModelLoad = {isExist: isExistPopupModelLoad, setExist: setIsExistPopupModelLoad};

    return (
        <>
            <GeneralPopup {...popupStatesPopupModel}>
                <ModelProvider>
                    <ModelBlock model={model}/>
                </ModelProvider>
            </GeneralPopup>

            <GeneralPopup {...popupStatesModelLoad}>
                <ModelProvider>
                    <ModelLoadPopup subproduct={subproduct}/>
                </ModelProvider>
            </GeneralPopup>


            <S.SubproductBlock>
                <S.VisualBlock>
                    <S.SubproductImage src={add_base_url(subproduct.image)}/>
                    <S.Buttons>
                        <S.TopButtons>
                            <S.PinkButton onClick={()=> setIsExistPopupModel(true)}>3D<br/>модель</S.PinkButton>
                            <S.PinkButton onClick={()=> setIsExistPopupModelLoad(true)}>Воссоздать 3D модель<br/>со своим макетом</S.PinkButton>
                        </S.TopButtons>
                        <S.BottomButtons>
                            <S.SeeMoreButton>Смотреть примеры работ</S.SeeMoreButton>
                        </S.BottomButtons>
                    </S.Buttons>
                </S.VisualBlock>

                <S.CalcBlock>
                    <S.CalcBlockWrapper>
                        <S.SubproductHeader>{subproduct.name}</S.SubproductHeader>
                        <S.SubproductDescription>{addBrToString(subproduct.description)}</S.SubproductDescription>

                        <S.OptionsBlock>

                        </S.OptionsBlock>
                    </S.CalcBlockWrapper>
                </S.CalcBlock>
            </S.SubproductBlock>
        </>
    );
};

export default SubproductBlock;