import React from 'react';

/*Styles*/
import * as S from './styles/city_selector_styles';
import {AnimatePresence} from "framer-motion";
import {
    IconSquare
} from "@components/water_site_base/base_header/header_logo_and_buttons_line/styles/header_logo_and_buttons_line_styles";

/*Created components*/
import useClickOutside from "@core/hooks/useClickOutside";
import useCity from "@current/hooks/useCity";
import {toggleProductFinderWithDelay} from "@redux/redux_reducers/mobile_navigation";
import {useDispatch} from "react-redux";



const CitySelector = () => {
    const dispatch = useDispatch();
    const {city, cities, fetchCities, chooseCity} = useCity();
    const {isDefault, setIsDefault, wrapperRef} = useClickOutside();

    const handleButtonClick = async () => {
        await fetchCities();
        setIsDefault(false);
    };

    const handleChooseCity = (event) => {
        chooseCity(event);
        setIsDefault(true);
    };

    /**Induce mobile find product input**/
    const handleToggleProductFinder = () => {
        dispatch(toggleProductFinderWithDelay());
    };

    return (
        <>
            <S.CitySelector ref={wrapperRef}>
                <S.MainBlock
                    $expand={!isDefault}
                    onClick={handleButtonClick}
                    animate={{ width: isDefault ? 'auto' : '9rem' }}
                    transition={{
                        duration: 0.25,
                    }}>
                    <S.CityDummy>{city}</S.CityDummy>
                    <S.Arrow $expand={!isDefault}></S.Arrow>
                </S.MainBlock>

                <AnimatePresence>
                    {!isDefault && (
                        <S.ChoseCityBlock
                            initial={{height: "0", opacity: 0}}
                            animate={{height: "8rem", opacity: 1 }}
                            exit={{height: "0", opacity: 0}}
                            transition={{
                                duration: 0.25,
                            }}>
                           <S.InsideCityBlock>
                               <S.CityBlockHeader>Доступные города</S.CityBlockHeader>
                               {cities?.length > 0 && (
                                   cities.map((item) => (
                                       <S.ChooseCityBlock onClick={handleChooseCity} key={item.id}>{item.name}</S.ChooseCityBlock>
                                   ))
                               )}
                           </S.InsideCityBlock>
                        </S.ChoseCityBlock>
                    )}
                </AnimatePresence>
            </S.CitySelector>

            <IconSquare onClick={handleToggleProductFinder}>
                <S.MagnifierIcon />
            </IconSquare>
        </>
    );
};

export default CitySelector;