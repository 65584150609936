import styled from 'styled-components';



export const BaseHeader = styled.div`
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.25s linear;

  /*175%*/
  @media (max-width: 1200px) {
    position: fixed;
    top: 0;
    height: ${props => props.$menuSize  ? '100%' : 'auto'};
    z-index: 10;
  }
`

export const MainNav = styled.div`
    height: 15%;
`

export const a3 = styled.div`

`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`