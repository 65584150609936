import styled from 'styled-components';
import {Link} from "react-router-dom";
import Logos from "@values/logos";
import Icons from "@values/icons";
import MagnifierWS from "@assets/icons/magnifier_ws.svg";
import {motion} from "framer-motion";



export const GeneralWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  height: 4.15rem;
  padding-top: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

/**Extreme parts**/
export const ExtremeParts = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(props) => {
    if (props.$side === 'left') return 'flex-start';
    if (props.$side === 'right') return 'flex-end';
  }};
`

/**Center part**/
export const CenterPart = styled.div`
  height: 100%;
  flex-basis: 0;
  flex-grow: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainLogo = styled(Link)`
  display: block;
  background: url(${Logos.FullLogoWaterSite}) no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
`
