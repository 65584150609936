import React, {useContext} from 'react';

/*Styles*/
import * as S from './styles/consultation_block_styles';
import FollowContext from "@entry_point/initalizers/follow_context";



const ConsultationBlock = () => {
    const {setIsExistFollowPopup} = useContext(FollowContext);

    const openFollowPopup = () =>{
        setIsExistFollowPopup(true);
    }

    return (
        <S.ConsultationBlock id='cooperation'>
            <S.Header>Готовы присоединиться?</S.Header>
            <S.AdditionalText>Оставьте ваши контактные данные.<br/>Мы свяжемся с вами в момент итогового запуска платформы.</S.AdditionalText>
            <S.ButtonsBlock>
                <S.Button onClick={openFollowPopup}>Оставить заявку</S.Button>
            </S.ButtonsBlock>
        </S.ConsultationBlock>
    );
};

export default ConsultationBlock;
