/*React*/
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

/*Created components*/
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import {PageText} from "@core/components/form_slider_components/general_popup/page_components/page_additonal";
import addBrToString from "@core/functions/add_br_in_string";
import {
    PageButton,
    PageButtons__Wrapper
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import {noClose, setTextYesNoPopup, yesClose} from "@src/current/entry_point/redux/redux_reducers/yes_no_popup_slice";

/*Styles*/




const YesNoPopup = () => {
    const dispatch = useDispatch();
    const yesNoPopupText = useSelector(state => state.YesNoPopupReducer.yesNoPopupText);

    /**Yes-No PopUp**/
    const [isExistPopup, setIsExistPopup] = useState(false);
    const popupStates = {isExist: isExistPopup, setExist: setIsExistPopup};

    useEffect(()=>{
        if (yesNoPopupText){
            setIsExistPopup(true)
        }
    },[yesNoPopupText])

    const closeFunc = () => {
        dispatch(setTextYesNoPopup(null));
        setIsExistPopup(false)
    }

    const handlerYesClose = () => {
        dispatch(yesClose());
        closeFunc();
    };

    const handlerNoClose = () => {
        dispatch(noClose());
        closeFunc();
    };

    return (
        <GeneralPopup {...popupStates}>
            <PageWrapper>
                {yesNoPopupText &&
                    <PageText>{addBrToString(yesNoPopupText)}</PageText>
                }

                <PageButtons__Wrapper>
                    <PageButton onClick={handlerYesClose}>Да</PageButton>
                    <PageButton onClick={handlerNoClose}>Нет</PageButton>
                </PageButtons__Wrapper>
            </PageWrapper>
        </GeneralPopup>
    );
};

export default YesNoPopup;
