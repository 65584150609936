import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";
import Icons from "@values/icons";
import {Link} from "react-router-dom";



export const NavPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  /*400%*/
  @media (max-width: 600px) {
    width: 80%;
  }
`

export const NavItem = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 1.3rem;
  width: auto;
  margin: 0 0.7rem;
  padding-bottom: 0.025rem;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.25s linear;
  color: black;
  gap: 1rem;
  
  &.question{
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 85%;
      background: gray;
      left: -8%;
      bottom: 0;
      transform: scaleX(1);
      z-index: -1;
      padding: 0.1rem 0.5rem;
      border-radius: 1.2rem;
      background: var(--BLUE_2);
      transition: 0.25s all ease;
      transition-delay: 0.025s;
    }
    
    &:hover::after{
      background: var(--BLUE_1);
    }
  }
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -5%;
    width: 110%;
    height: 0.125rem;
    background-image: linear-gradient(to right, var(--BLUE_LIGHT), var(--BLUE_DARK));
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s ease-out;
    transition-delay: 0.025s;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  /*400%*/
  @media (max-width: 600px) {
    font-size: 1rem;
    height: 2rem;
  }
`


/**Non-standard | General**/
export const ExtremeRightNavItemBlock = styled.div`
  position: relative;
  padding-bottom: 0.05rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid transparent;
  border-radius: 1.2rem;
  box-sizing: border-box;
  justify-content: flex-start;
  cursor: pointer;
`

export const ExtremeNavItemText = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
`

export const ExtremeNavItemIcon = styled.div`
  background: url(${Icons.FilledArrow}) no-repeat center;
  background-size: contain;
  width: 0.65rem;
  height: 0.65rem;
`


/**Scroll to right**/
export const ExtremeRightNavItemBlock__ScrollToRight = styled(ExtremeRightNavItemBlock)`
  margin-left: 0.5rem;
  background: linear-gradient(white 0 0) padding-box,
  linear-gradient(to right, white 20%, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;

  &:hover{
    background: linear-gradient(white 0 0) padding-box,
    linear-gradient(to right, white 10%, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  }
`

export const ExtremeNavItemText__ScrollToRight = styled(ExtremeNavItemText)`
  margin-right: ${(props) => (props.$isReadyToChange ? '0.25rem': '0')};
`

export const ExtremeNavItemIcon__ScrollToRight = styled(ExtremeNavItemIcon)`
  margin: 0.05rem 0.2rem 0 0.15rem;
  transform: rotate(180deg);
`

/**Scroll to left**/
export const ExtremeRightNavItemBlock__ScrollToLeft = styled(ExtremeRightNavItemBlock)`
  margin-right: 0.5rem;
  background: linear-gradient(white 0 0) padding-box,
  linear-gradient(to left, white 20%, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;

  &:hover{
    background: linear-gradient(white 0 0) padding-box,
    linear-gradient(to left, white 10%, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  }
`

export const ExtremeNavItemText__ScrollToLeft = styled(ExtremeNavItemText)`
  margin-left: ${(props) => (props.$isReadyToChange ? '0.25rem': '0')};
`

export const ExtremeNavItemIcon__ScrollToLeft = styled(ExtremeNavItemIcon)`
  margin: 0.05rem 0.15rem 0 0.2rem;
  transform: rotate(0deg);
`
