import React from 'react';

/*Styles*/
import * as S from './styles/reference_button_styles';



const ReferenceButton = () => {
    return (
        <S.ReferenceButton>
            <S.ReferenceType>Доставка</S.ReferenceType>
            <S.ThemeText>
                Как оформить<br/>доставку?
            </S.ThemeText>
        </S.ReferenceButton>
    );
};

export default ReferenceButton
