import React from 'react';
import {createBrowserRouter} from "react-router-dom";

/*Created components*/
import BaseTemplateStartapp from "@pages/startapp/base_template_startapp/base_template_startapp";
import MainPageStartapp, {mainPageStartappLoader} from "@pages/startapp/main_page_startapp/main_page_startapp";
import ScrollToAnchorWrapper from "@components/water_site_base/scroll_to_anchor_wrapper/scroll_to_anchor_wrapper";



const router_startapp = createBrowserRouter([
    {
        path: '/',
        element: (
            <BaseTemplateStartapp/>
        ),
        children: [
            {
                loader: mainPageStartappLoader,
                path: '',
                element: <ScrollToAnchorWrapper><MainPageStartapp/></ScrollToAnchorWrapper>
            },
        ]
    },
]);

export default router_startapp;
