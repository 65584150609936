import styled, {css} from 'styled-components';
import {WithoutBlueHighlighting, WithoutUserPointers} from "@core/css/css_layouts";
import {StandardButtonThin} from "@current/main/stylistic/standard_button";


export const ProductButtonBlock = styled.div`
  margin: 0.8rem 1.3rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.8rem;
  cursor: pointer;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  color: black;
  ${WithoutBlueHighlighting};

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0.04rem 0.4rem #cacaca;
  }
`

export const ProductImage = styled.img`
  object-fit: cover;
  width: 13rem;
  height: 9rem;
  border-radius: 0.8rem;

  /*175%*/
  @media (max-width: 1200px) {
    width: 11.5rem;
    height: 8rem;
  }

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    width: 10.8rem;
    height: 7.5rem;
  }
`

export const ProductName = styled.span`
  margin-top: 0.25rem;
  font-size: 1.15rem;
  ${WithoutUserPointers};
`

export const ProductButton = styled(StandardButtonThin)`
  margin-top: 0.35rem;
  ${(props) =>
          props.$isReadyToChange &&
          css`
            background: var(--BLUE_DARK);
            color: white;
          `
  }
`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`