import styled from 'styled-components';



export const TemplateSlider = styled.div`
  width: 100vw;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 72.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const TemplateSliderWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  width: 85%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  
  /**Phone**/
  /*300%*/
  @media (max-width: 700px) {
    width: 90%;
  }
`
