import React, {useContext, useEffect} from 'react';

/*Styles*/
import * as S from './styles/model_load_popup_styles';

/*Created components*/
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import ModelLoadContext from "@components/water_site_base/model_load_popup/model_load_context";
import SliderContext from "@core/components/slider/context";
import PopupInsideContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";



const ChooseModelForm = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {subproduct, setModelData} = useContext(ModelLoadContext)
    const { setActivePageName, definedPages, setDefinedPages} = useContext(PopupInsideContext);

    useEffect(()=>{
        setActivePageName('ChooseModelForm')
        setDefinedPages({...definedPages, pageOne: 'ChooseModelForm'})
    },[])

    const slideToLoadImages = (item) => {
        setActivePageName('LoadImagesForm')
        setDefinedPages({...definedPages, pageTwo: 'LoadImagesForm'})
        setModelData((prev) => ({
            ...prev,
            SelectedModel: item,
        }))
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Выбор типа 3D модели</PageHeader>

                <S.ButtonsBlock>
                    <S.ButtonsBlockInside>
                        {subproduct.models.map((item) => (
                            <S.ButtonChooseButton onClick={()=>slideToLoadImages(item)}>{item.btn_text}</S.ButtonChooseButton>
                        ))}
                    </S.ButtonsBlockInside>
                </S.ButtonsBlock>
            </PageWrapper>
        </MotionDiv>
    );
};

export default ChooseModelForm;