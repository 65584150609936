import styled from 'styled-components';
import {ArticlesButton} from "@current/main/stylistic/standard_button";



export const NewsSlider = styled.div`
  margin-top: 1.25rem;
  width: 100vw;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 72.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const SliderExternalWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
`

export const SlideWrapperInside = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const SeeMore = styled(ArticlesButton)`
  background-image: linear-gradient(to top, var(--NEWS_BLUE_LIGHT), var(--NEWS_BLUE_DARK));
`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`