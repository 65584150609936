import React from 'react';

/*Styles*/
import * as S from './styles/navigation_styles';

/*Created components*/
import FirstNavPage from "@components/water_site_base/base_header/navigation/pages/first_nav_page";



const Navigation = () => {

    return (
        <S.Navigation>
            <FirstNavPage/>
        </S.Navigation>
    );
};

export default Navigation;