import React, {useEffect, useRef, useState} from 'react';

/*Styles*/
import * as S from './styles/model_block_styles';

/*Created components*/
import {useModel} from "@current/main/functions/3D/model_provider";
import {useLoaderData} from "react-router-dom";
import {FeaturesMenuInside} from "./styles/model_block_styles";


const ModelBlock = () => {
    const {mainPageItems} = useLoaderData();
    const [models, setModels] = useState()
    const [modelIndex, setModelIndex] = useState(null)

    useEffect(()=>{
        if (mainPageItems && mainPageItems.data){
            setModels(mainPageItems.data.models)
        }
    },[mainPageItems])

    const modelCanvasRef = useRef(null);
    const {createScene, modelArgs, setModelArgs, resetScene, spinningRef, setModelArgsFromInstance, MakePhoto, toggleLightHelpers} = useModel();

    useEffect(() => {
        if (models) {
            setModelIndex(models[0].order)
            setModelArgsFromInstance(models[0]);
        }
    }, [models])

    useEffect(() => {
        if (modelArgs.ModelPath && modelArgs.Z_Value !== undefined && modelCanvasRef.current) {
            createScene(modelCanvasRef.current, true);
        }
    }, [modelArgs.ModelPath, modelArgs.Z_Value, createScene]);


    const [spinning, setSpinning] = useState(true)

    const handlerStopAnimations = () =>{
        spinningRef.current = !spinningRef.current;
        setSpinning((prev) => !prev);
    }

    const handlerClickOtherModel = (index) =>{
        resetScene();
        setModelIndex(models[index].order)
        setModelArgsFromInstance(models[index]);
    }

    return (
        <S.ModelBlock>
            <S.ModelCanvas ref={modelCanvasRef}/>

            <S.TopButtonsBlock>
                <S.LeftPart>
                    <S.PlayButton $status={spinning} onClick={handlerStopAnimations}/>
                </S.LeftPart>

                <S.RightPart>
                    <S.PhotoButton onClick={MakePhoto}/>
                </S.RightPart>
            </S.TopButtonsBlock>

            <S.FeaturesMenu>
                <S.FeaturesMenuInside>
                    {models?.length > 0 &&
                        models.map((item, index) => (
                            index === modelIndex-1?
                                <S.FeaturesBlock key={index} className='active'>
                                    {item.btn_text}
                                </S.FeaturesBlock>
                                :
                                <S.FeaturesBlock key={index} onClick={()=>handlerClickOtherModel(index)}>
                                    {item.btn_text}
                                </S.FeaturesBlock>
                        ))
                    }
                </S.FeaturesMenuInside>
            </S.FeaturesMenu>
        </S.ModelBlock>
    );
};

export default ModelBlock;




