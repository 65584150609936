import React, {useContext} from 'react';

/*Styles*/
import * as S from './styles/base_footer_styles';
import FollowContext from "@entry_point/initalizers/follow_context";

/*Created components*/



const BaseFooter = () => {
    const {setIsExistFollowPopup} = useContext(FollowContext);

    const openFollowPopup = () =>{
        setIsExistFollowPopup(true);
    }


    return (
        <S.BaseFooter id="contacts">
            <S.Wrapper>
                <S.WrapperForPart>
                    <S.ContactsBlock>
                        <S.ContactsHeader>Контакты</S.ContactsHeader>
                        <S.ContactsPhone>+7 (982) 913 93 92</S.ContactsPhone>
                        <S.ContactsEmail>waterprint@list.ru</S.ContactsEmail>
                    </S.ContactsBlock>
                </S.WrapperForPart>



                <S.FasieBlock>
                    <S.FasieHeader>При поддержке:</S.FasieHeader>
                    <S.FasieIcon href={'https://fasie.ru/'}/>
                    <S.FasieSeparator/>
                    <S.PutpIcon href={'https://univertechpred.ru/'}/>
                </S.FasieBlock>



                <S.WrapperForPart className={"right"}>
                    <S.FollowBlock>
                        <S.FollowHeader>Получай уведомления<br/>о новостях платформы</S.FollowHeader>
                        <S.FollowButton onClick={openFollowPopup}>Подписаться</S.FollowButton>
                    </S.FollowBlock>
                </S.WrapperForPart>
            </S.Wrapper>

            <S.CompanyInfo>
                ООО «ПС»<br/>
                (ОГРН 1237200017911, ИНН 7203564069,
                625005,
                Тюменская область,
                г. Тюмень,
                ул. Муравленко,
                д. 5,
                кв. 10)
            </S.CompanyInfo>

            <S.VersionBlock>
                <S.VersionItem>Версия: 0.0.4</S.VersionItem>
                <S.VersionItem>WATER-SITE.RU 2024</S.VersionItem>
                <S.VersionItem></S.VersionItem>
            </S.VersionBlock>
        </S.BaseFooter>
    );
};

export default BaseFooter;
