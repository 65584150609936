import { createGlobalStyle } from 'styled-components';



const GlobalStyle = createGlobalStyle`
  :root {
    --STANDARD: black;
    --ADDIT: GREY;
  
    /**Company main colors**/
    /*Logo colors*/
    --BLUE_LIGHT: #a5dcf3;
    --BLUE_DARK: #98b4e2;
    /*Other*/
    --BLUE_1: #ccdbf1;
    --BLUE_2: #eaf2fd;
    --BLUE_3: #f6f9fe;
    
    /**Main colors**/
    /*Black-Grey-White*/
    --BLACK_1: #40393b;
    --BLACK_2: #7e7e7f;
    --BLACK_3: #abaeb3;
    --BLACK_4: #cbcbcb;
    --BLACK_5: #eaeaea;
    --BLACK_6: #f4f4f4;
    
    /*Green*/
    /*Gradient*/
    --GREEN_LIGHT: #90eac5;
    --GREEN_DARK: #8ae0bd;
    
    --GREEN_1: #90e9c5;
    
    /*Yellow*/
    --YELLOW_1: #e6be0f;
    --YELLOW_2: #e8dc54;
    
    /*Red*/
    --RED_1: #ff9a9d;
    
    /*Pink*/
    --PINK_1: #e296bf;
    
    /**Special**/
    /*References*/
    --VIOLET_LIGHT: #ebe5ef;
    --VIOLET_DARK: #ced1ee;

    /*News*/
    --NEWS_BLUE_LIGHT: #bce3f5;
    --NEWS_BLUE_DARK: #b3c8e9;
  }
  
  html{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    
    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
    }

    &::-webkit-scrollbar-track {
      opacity: 1;
      background: var(--BLACK_4);
    }

    /**PC**/
    /*110%*/
    @media (max-width: 1800px) {
      font-size: 23px;
    }

    /*125%*/
    @media (max-width: 1600px) {
      font-size: 22px;
    }

    /**Pad**/
    /*150%*/
    @media (max-width: 1400px) {
      font-size: 19px;
    }

    /*175%*/
    @media (max-width: 1200px) {
      font-size: 18px;
    }

    /*200%*/
    @media (max-width: 1000px) {
      font-size: 16px;
    }

    /**Phone**/
    /*250%*/
    @media (max-width: 900px) {
      font-size: 14px;
    }

    /*300%*/
    @media (max-width: 700px) {
      font-size: 14px;
    }

    /*400%*/
    @media (max-width: 600px) {
      font-size: 12px;
    }
    
    /*500%*/
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
  
  body {
    width: 100%;
    max-width: 100%;
    min-width: 360px;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    ${props => props.$disableTransitions && `* {transition: none !important;}`};
    scrollbar-gutter: stable;
    
    &.isMobileMenu {
      overflow: hidden;
    }
    
    html > div[style] {
      display: none !important;
    }
    
    *:focus {
      outline: none;
    }
    
    *{
      margin: ${props => props.$margin}rem;
    }
    
    a{
      text-decoration: none;
    }

    br {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input {
      all: unset;
      outline: none;
    }
    
    canvas {
      display: block;
    }
  }
`;

export default GlobalStyle;

