import Products1 from "@assets/startapp/products_startapp.png"
import Products2 from "@assets/startapp/performers_startapp.png"
import Design1 from "@assets/startapp/design_startapp.png"
import Design2 from "@assets/startapp/result_design.png"


const Other = {
    Products1,
    Products2,
    Design1,
    Design2
};

export default Other;