import axios from 'axios';



const baseURL = process.env.BASE_URL;


/**Api client**/
const ApiClient = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

export default ApiClient;



/**Interceptors**/
ApiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});



ApiClient.interceptors.response.use(response => response, async error => {
    if (error.response.status === 401){
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            const url_to_refresh = `${baseURL} + /api/token/refresh/`
            const response = await axios.post(url_to_refresh, {refresh: refreshToken}, {headers: {'Content-Type': 'application/json'}});
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
        }
        else{
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }
    }
    else if(error.response.status === 400){
        return Promise.reject(error);
    }
})



