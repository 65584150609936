import {css} from 'styled-components';
import {WithoutUserPointers} from "@core/css/css_layouts";



export const DefaultButton__Wrapper = css`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.15rem 0.45rem;
  border:  0.15rem solid transparent;
  background:
          linear-gradient(white 0 0) padding-box, /*Фоновый цвет*/
          linear-gradient(to top, black, black) border-box; /*Градиент обводка*/

  &:hover{
    color: white;
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background-image: linear-gradient(to top, black, black);
    opacity: 0;
    transition: all ease 0.25s;
  }

  &:hover::before{
    position: absolute;
    opacity: 1;
    transition-delay: 0.025s;
  }
`

export const DefaultButton__Text = css`
  ${WithoutUserPointers};
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 2;
  transition: all ease 0.25s;
`