import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/design_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import Other from "@values/startapp/other";
import Products2 from "@assets/startapp/performers_startapp.png";


const DesignSlider = () => {
    return (
        <S.ProductSlider id='design'>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Дизайн"}
                />

                <S.ProductsSliderWrapper_1>
                    <S.LeftWrapper>
                        <S.StandardImage src={Other.Design1}></S.StandardImage>
                    </S.LeftWrapper>

                    <S.RightWrapper>
                        <S.TextWrapper>
                            <S.Header>ПРЕОБРАЗУЙТЕ ИДЕИ В РЕАЛЬНОСТЬ</S.Header>
                            <S.SimpleText>Плафторма позволяет создавать собственные
                                макеты, используя готовые шаблоны и элементы.
                            </S.SimpleText>
                        </S.TextWrapper>
                    </S.RightWrapper>
                </S.ProductsSliderWrapper_1>

                <S.ProductsSliderWrapper_2>
                    <S.LeftWrapper>
                        <S.TextWrapper>
                            <S.SimpleText>Загружайте полученный макет на свой компьютер или сохраняйте на платформе, для использования в будущих заказах.
                            </S.SimpleText>
                        </S.TextWrapper>
                    </S.LeftWrapper>

                    <S.RightWrapper className={'center'}>
                        <S.NonStandardImage src={Other.Design2}></S.NonStandardImage>
                    </S.RightWrapper>
                </S.ProductsSliderWrapper_2>
            </S.Wrapper>
        </S.ProductSlider>
    );
};

export default DesignSlider;