import React, {useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/profile_button_styles';
import {Link} from "react-router-dom";
import {
    IconSquare
} from "@components/water_site_base/base_header/header_logo_and_buttons_line/styles/header_logo_and_buttons_line_styles";

/*Created components*/
import useHoverAction from "@current/main/functions/stylistic/useHoverAction";
import AuthContext from "@entry_point/initalizers/auth_context";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import AuthPopup from "@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/auth_pop-up";




const ProfileButton = () => {
    /**Change Icon color**/
    const wrapperIconBlockRef = useRef(null)
    const isReadyToChange = useHoverAction(wrapperIconBlockRef, false);

    /**Auth check**/
    const {isAuthenticated} = useContext(AuthContext)

    /**Popup**/
    /**Auth popup**/
    const [isExistPopupAuth, setIsExistPopupAuth] = useState(false);
    const popupStatesAuth = {isExist: isExistPopupAuth, setExist: setIsExistPopupAuth};

    return (
        <>
            {!isAuthenticated && (
                <GeneralPopup {...popupStatesAuth}>
                    <AuthPopup />
                </GeneralPopup>
            )}

            <S.ProfileButton
                as={isAuthenticated ? Link : 'div'}
                to={isAuthenticated ? '/profile' : undefined}
                ref={wrapperIconBlockRef}
                onClick={() => setIsExistPopupAuth(true)}>
                <S.ProfileIcon $isAuthenticated={isAuthenticated} $isReadyToChange={isReadyToChange}/>
                <S.Text $isReadyToChange={isReadyToChange}>Профиль</S.Text>
            </S.ProfileButton>

            <IconSquare onClick={() => setIsExistPopupAuth(true)}>
                <S.ProfileIconMobile $isAuthenticated={isAuthenticated}/>
            </IconSquare>
        </>
    );
};

export default ProfileButton;