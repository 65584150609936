import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/main_page_slider_styles';

/*Created components*/
import {useLoaderData} from "react-router-dom";
import Slider from "@core/components/slider/slider";
import add_base_url from "@core/functions/add_base_url";



const MainPageSlider = () => {
    const {mainPageItems: slider_loader} = useLoaderData();
    const [sliders, setSliders] = useState([])

    useEffect(()=>{
        if (slider_loader && slider_loader.data){
            setSliders(slider_loader.data.sliders)
        }
    },[slider_loader])

    const SliderSettings = {
        onArrows: true,
        onSwitcher: true,
        infinite: true,
        dragging: true,
        slickPercent: 20,
    };

    return (
        <>
            {sliders.length > 0 && (
                <S.MainPageSlider>
                    <Slider {...SliderSettings}>
                        {sliders.map((item) => (
                        <Slider.Slide key={item.id}>
                            <S.SliderBlock>
                                <S.SliderImage $src_pc={add_base_url(item.slider_image_pc)} $src_mobile={add_base_url(item.slider_image_mobile)}/>

                                <S.SliderTextBlock>
                                    <S.SliderTextHeader>
                                        Единое решение <br/> для вашего проекта
                                    </S.SliderTextHeader>

                                    <S.SliderTextInfo>
                                        Широкий ассортимент продукции,<br/>
                                        объединяющий несколько типографий<br/>
                                        и множество креативных мастеров.
                                    </S.SliderTextInfo>
                                </S.SliderTextBlock>
                            </S.SliderBlock>
                        </Slider.Slide>
                        ))}
                    </Slider>
                </S.MainPageSlider>
            )}
        </>
    );
};

export default MainPageSlider;