import React from 'react';

/*Styles*/
import * as S from './styles/components_see_more_button';



const ComponentsSeeMoreButton = () => {
    return (
        <S.ComponentsSeeMoreButton>
            <S.Button>Смотреть больше</S.Button>
        </S.ComponentsSeeMoreButton>
    );
};

export default ComponentsSeeMoreButton;