/*React*/
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Floating UI*/
import {
    arrow, autoUpdate,
    offset,
    useFloating, useTransitionStyles,
} from "@floating-ui/react";

/*Styles*/
import * as S from './styles/styles';

/*Created components*/
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import addBrToString from "@core/functions/add_br_in_string";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";





const ErrorTooltip = ({tooltipName}) => {
    const {handleSetPerformAction} = useContext(inputsFormContext)
    const {activePageName} = useContext(PopupInsideContext)
    const [errorText, setErrorText] = useState(null);
    const timerRef = useRef(null)

    const performAction = (errorFromParentAction) => {
        if (errorFromParentAction){
            console.log(errorFromParentAction)
            console.log(tooltipName)
            console.log(errorFromParentAction[tooltipName])
            if (errorFromParentAction[tooltipName]){
                setErrorText(errorFromParentAction[tooltipName][0])

                if (timerRef.current){
                    clearTimeout(timerRef.current);
                }
                setIsOpen(true);
                timerRef.current = setTimeout(()=>{
                    setIsOpen(false);
                }, 2500);
            }
        }
    };

    useEffect(() => {
        if (handleSetPerformAction) {
            handleSetPerformAction(performAction);
        }
    }, [handleSetPerformAction]);


    useEffect(() =>{
        setIsOpen(false)
    },[activePageName])

    /**Tooltip settings**/
    const [isOpen, setIsOpen] = useState(false);

    const arrowRef = useRef(null);
    const ARROW_HEIGHT = 5;
    const GAP = 1;

    const {refs, floatingStyles, context} = useFloating({
        placement: "bottom",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(ARROW_HEIGHT + GAP),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const {isMounted, styles} = useTransitionStyles(context, {
        duration: {
            open: 1000,
            close: 1000,
        },
    });

    return (
        <>
            <div ref={refs.setReference} />

            {isMounted && (
                <S.TooltipBlock
                    ref={refs.setFloating}
                    style={{...floatingStyles, ...styles}}
                    $zIndex = {'100'}>
                    <S.Arrow className="arrow" ref={arrowRef} context={context} width={10} height={ARROW_HEIGHT} />
                    {addBrToString(errorText)}
                </S.TooltipBlock>
            )}
        </>
    );
};

ErrorTooltip.propTypes = {
    onParentClick: PropTypes.func,
};

export default ErrorTooltip;