import styled from 'styled-components';
import OtherImages from "@values/other_images";
import ModelIcons from "@values/model_icons";



export const ModelBlock = styled.div`
  position: relative;
  background: url(${OtherImages.ModelsDefaultBackground}) no-repeat center;
  background-size: cover;
  overflow: hidden;
  border-radius: 0.45rem;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
`

export const ModelCanvas = styled.canvas`
  height: 27rem;
  width: 100%;
`

export const TopButtonsBlock = styled.div`
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  position: absolute;
  top: 0;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
`

export const LeftPart = styled.div`
  display: flex;
  align-items: flex-start;
`

export const RightPart = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ModelIconBlock = styled.div`
  width: 1.8rem;
  aspect-ratio: 1/1;
  filter: invert(100%);
  opacity: 50%;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  cursor: pointer;
  background-size: contain;
  
  &:hover{
    opacity: 100%;
  }
`

export const PlayButton = styled(ModelIconBlock)`
  background: ${props => props.$status  ? `url(${ModelIcons.Stop})` : `url(${ModelIcons.Play})`} no-repeat center;
`

export const PhotoButton = styled(ModelIconBlock)`
  background: url(${ModelIcons.MakePhoto});
  margin-right: 0.65rem;
`

export const VideoButton = styled(ModelIconBlock)`
  background: url(${ModelIcons.MakeVideo}) no-repeat center;
`


/**Features menu**/
export const FeaturesMenu = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 7rem;
  height: 80%;
  border-radius: 0 0.4rem 0 0;
  overflow: hidden;
  border-right: 0.1rem solid white;
  border-top: 0.1rem solid white;

  /*500%*/
  @media (max-width: 400px) {
    width: 5rem;
  }
`

export const FeaturesMenuInside = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const FeaturesBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  text-align: center;
  border-bottom: 0.1rem solid white;
  color: white;
  transition: 0.25s all ease;
  cursor: pointer;
  
  &.active{
    background:white;
    color: black;
    opacity: 1;
    cursor: default;
  }
  
  &:hover {
    background:white;
    color: black;
    opacity: 1;
  }

  /*500%*/
  @media (max-width: 400px) {
    font-size: 0.45rem;
  }
`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`