import React from 'react';

/*Styles*/
import * as S from './styles/articles_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import Slider from "@core/components/slider/slider";
import ArticleButton from "@components/water_site_base/article_button/article_button";



const ArticlesSlider = () => {
    const SliderSettings = {
        onArrows: true,
        infinite: true,
    };

    return (
        <S.ArticleSlider>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Полезные статьи"}
                />

                <S.SliderExternalWrapper>
                    <Slider {...SliderSettings}>
                        <Slider.Slide>
                            <ArticleButton/>
                            <ArticleButton/>
                            <ArticleButton/>
                            <ArticleButton/>
                            <ArticleButton/>
                        </Slider.Slide>

                        <Slider.Slide>

                        </Slider.Slide>
                    </Slider>
                </S.SliderExternalWrapper>
            </S.Wrapper>
        </S.ArticleSlider>
    );
};

export default ArticlesSlider;
