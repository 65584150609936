import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/styles.js';
import {AnimatePresence} from 'framer-motion';
import {Square} from "./styles/styles.js";



const Loader = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const handlePageLoad = () => {
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        };

        if (document.readyState === 'complete') {
            handlePageLoad();
        } else {
            window.addEventListener('load', handlePageLoad);
        }

        return () => {
            window.removeEventListener('load', handlePageLoad);
        };
    }, []);


    return (
        <>
            <AnimatePresence>
                {isLoading && (
                    <S.Loader__Wrapper
                        initial={{opacity: 1}}
                        exit={{opacity: 0}}>
                        <S.Loader className={'l_main'}>
                            <div className="l_main">
                                <Square index={0}>
                                    <span />
                                    <span />
                                    <span />
                                </Square>
                                <Square index={1}>
                                    <span />
                                    <span />
                                    <span />
                                </Square>
                                <Square index={2}>
                                    <span />
                                    <span />
                                    <span />
                                </Square>
                                <Square index={3}>
                                    <span />
                                    <span />
                                    <span />
                                </Square>
                            </div>
                        </S.Loader>
                    </S.Loader__Wrapper>
                )}
            </AnimatePresence>
        </>
    );
};

export default Loader;


