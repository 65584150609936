import * as THREE from 'three';



export function createWhiteTexture() {
    const whiteTexture = new THREE.Texture();
    const whiteImage = document.createElement('canvas');
    whiteImage.width = 1;
    whiteImage.height = 1;
    const context = whiteImage.getContext('2d');
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, 1, 1);
    whiteTexture.image = whiteImage;
    whiteTexture.needsUpdate = true;
    return whiteTexture;
}
