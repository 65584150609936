/*React*/
import React, {useContext, useEffect, useState} from 'react';

/*Created components*/
import AuthContext from "@entry_point/initalizers/auth_context";
import SliderContext from '@core/components/slider/context';
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {slideUpDown} from '@core/components/form_slider_components/general_popup/motion_animations/animations';
import useFormData from '@core/hooks/useFormData';
import {useDispatch} from "react-redux";
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import PhoneEmailInput from "@core/components/form_slider_components/general_popup/input_components/phone_email_input";
import PasswordInput from "@core/components/form_slider_components/general_popup/input_components/password_input";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import {setTextAndHeaderOkPopup} from "@src/current/entry_point/redux/redux_reducers/ok_popup_slice";

/*Styles*/
import {AnimatePresence} from 'framer-motion';
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const Entry = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {closePopupFunc} = useContext(PopupExternalContext);
    const {activePageName, setActivePageName, definedPages, setDefinedPages} = useContext(PopupInsideContext);
    const {setIsAuthenticated} = useContext(AuthContext)
    const dispatch = useDispatch();
    /*Extra states*/
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    let noChangeActivePages = ['Entry', 'Register', 'ChangePasswordAddNewPassword'];

    useEffect(()=>{
        setActivePageName('Entry')
        setDefinedPages({...definedPages, pageOne: 'Entry'})
    },[])

    const slideToRegister = () => {
        setIsFirstOpen(false);
        setActivePageName('Register');
        setDefinedPages({...definedPages, pageTwo: 'Register'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const handlerAddChangePasswordPage = () => {
        setIsFirstOpen(false);
        setActivePageName('ChangePassword');
        setDefinedPages({...definedPages, pageTwo: 'ChangePassword'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**API**/
    const {formData, handleChange} = useFormData({
        contact_info_type: '',
        contact_info: '',
        password: '',
    });

    const successFunction = (successData) =>{
        localStorage.setItem('access_token', successData.access);
        localStorage.setItem('refresh_token', successData.refresh);
        closePopupFunc();
        setIsAuthenticated(true);
        dispatch(setTextAndHeaderOkPopup({text: "Вы успешно вошли в аккаунт!", header: ""}))
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Вход в личный аккаунт</PageHeader>

                <InputsForm requestUrl={'api/users/login/'} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <PhoneEmailInput/>

                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={isFirstOpen ? 'noAnimInitial' : 'usualInitial'}
                                              animate='animate'
                                              exit='exit'
                                              variants={slideUpDown()}>
                                <PasswordInput isForgetPasswordButton={true} slideToChangePasswordFunc={handlerAddChangePasswordPage}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton>Войти</PageButton>
                        <PageButton onClick={slideToRegister}>Регистрация</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default Entry;
