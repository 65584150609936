import React from 'react';

/*Styles*/
import * as S from './styles/main_page_startapp_styles';

/*Created components*/
import MainPageSlider from "@components_startapp/main_page_slider/main_page_slider";
import About from "@components/water_site_base/about/about";
import ProductSlider from "@components_startapp/product_slider/product_slider";
import VisualizationBlock from "@components_startapp/visualization_block/visualization_block";
import ApiRequest from "@core/api/api_request";
import DesignSlider from "@components_startapp/design_slider/design_slider";
import ConsultationBlock from "@components_startapp/consultation_block/consultation_block";


export async function mainPageStartappLoader() {
    const mainPageItems = await ApiRequest("get", `api/loaders/main_page_startapp/`);
    return {mainPageItems};
}

const MainPageStartapp = () => {
    return (
        <>
            <MainPageSlider/>
            <About/>
            <ProductSlider/>
            <VisualizationBlock/>
            <DesignSlider/>
            <ConsultationBlock/>
        </>
    );
};

export default MainPageStartapp;