import styled from 'styled-components';
import Test from "@values/test/test";



export const ArticleButton = styled.div`
  position: relative;
  width: 11rem;
  height: 5.5rem;
  margin: 0 0.4rem;
  border-radius: 0.4rem;
  object-fit: contain;
  background: url(${Test.ArticleTest}) no-repeat center;
  background-size: contain;
  cursor: pointer;
`

export const a2 = styled.div`

`

export const a3 = styled.div`

`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`