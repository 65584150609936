import React from 'react';

/*Styles*/
import * as S from './styles/components_header_block_styles';



const ComponentsHeaderBlock = ({headerText, additionalText}) => {
    return (
        <S.ComponentsHeaderBlock>
            <S.Header>{headerText}</S.Header>
            {additionalText &&
                <S.AdditionalText>{additionalText}</S.AdditionalText>
            }
        </S.ComponentsHeaderBlock>
    );
};

export default ComponentsHeaderBlock;