import React from 'react';

/*Styles*/
import * as S from './styles/news_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import Slider from "@core/components/slider/slider";
import NewsButton from "@components/water_site_base/news_button/news_button";



const NewsSlider = () => {
    const SliderSettings = {
        onArrows: true,
        infinite: true,
    };

    return (
        <S.NewsSlider>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Новости платформы"}
                />

                <S.SliderExternalWrapper>
                    <Slider {...SliderSettings}>
                        <Slider.Slide>
                            <S.SlideWrapperInside>
                                <NewsButton/>
                                <NewsButton/>
                                <NewsButton/>
                                <NewsButton/>
                                <NewsButton/>
                            </S.SlideWrapperInside>
                        </Slider.Slide>

                        <Slider.Slide>

                        </Slider.Slide>
                    </Slider>
                </S.SliderExternalWrapper>

                <S.SeeMore>Смотреть все</S.SeeMore>

            </S.Wrapper>
        </S.NewsSlider>
    );
};

export default NewsSlider;
