import React from 'react';

/*Styles*/
import * as S from './styles/example_button_styles';

/*Created components**/
/**FIXME**/
import Test from "@assets/images/test/examples/example_test.jpg";
/**FIXME**/
import LineBreaker from "@components/water_site_base/example_button/line_breaker";



const ExampleButton = () => {
    return (
        <S.ExampleButton>
            <S.ExampleImage src={Test}/>
        </S.ExampleButton>
    );
};

ExampleButton.Break = LineBreaker

export default ExampleButton;