import styled from 'styled-components';
import {motion} from "framer-motion";
import Icons from "@values/icons";
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const MobileNavigation = styled.div`
  display: none;
  z-index: 3;
  ${WithoutBlueHighlighting};


  /*175%*/
  @media (max-width: 1200px) {
    position: relative;
    display: block;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
  }
`

export const LeftNav = styled(motion.div)`
  z-index: 3;
  height: 100%;
  width: 35%;
  background: white;

  /*300%*/
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const Background = styled(motion.div)`
  z-index: -2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  top: 0;
  right: 0;
  transition: 0.25s all ease;
`

export const ScrollBlock = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }

  &::-webkit-scrollbar-track {
    opacity: 1;
    background: var(--BLACK_4);
  }
`

const ScrollBlockItem = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2.5rem;
  min-height: 2.5rem;
  cursor: pointer;

  /*300%*/
  @media (max-width: 700px) {
    padding-left: 4rem;
    min-height: 3rem;
  }
`

export const CitySelectorBlock = styled(ScrollBlockItem)`
  font-weight: 500;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${props => props.$isDefault  ? 'var(--BLACK_6)' : 'var(--BLACK_5)'};
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  
  
  &:hover {
    background: var(--BLACK_5);
  }
`

export const CityArrow = styled.div`
  background: url(${Icons.FilledArrow}) no-repeat center;
  background-size: contain;
  padding-right: 0.7rem;
  margin-top: 0.1rem;
  min-width: 0.75rem;
  min-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s all ease;
  transform: ${props => props.$isDefault ? 'rotate(-90deg)' : 'rotate(90deg)'};
`

export const MenuItem = styled(ScrollBlockItem)`
  transition: 0.25s all ease;
  transition-delay: 0.025s;

  &.first{
    margin-top: 1rem;
  }

  &:hover {
    background: var(--BLUE_2);
  }

  &:last-child {
    margin: 1rem 0;
    background: var(--BLUE_2);
    font-weight: 500;

    &:hover {
      background: var(--BLUE_1);
    }
  }
`

export const ChooseCityBlock = styled(ScrollBlockItem)`
  min-height: auto;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  border-bottom: 0.2rem solid var(--BLACK_5);
`

export const City =  styled(ScrollBlockItem)`
  min-height: 2rem;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  
  &:hover{
    background: var(--BLACK_6);
  }
`