import styled from 'styled-components';
import {StandardButtonBold} from "@current/main/stylistic/standard_button";



export const ComponentsSeeMoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Button = styled(StandardButtonBold)``