import styled from 'styled-components';
import {Link} from "react-router-dom";
import Logos from "@values/logos";
import Icons from "@values/icons";
import MagnifierWS from "@assets/icons/magnifier_ws.svg";
import {motion} from "framer-motion";



export const GeneralWrapper = styled.div`
  position: relative;
  max-width: 100%;
`

export const HeaderLogoAndButtonsLine = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
  background: white;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    border-bottom: 0.1rem solid var(--BLUE_1);
  }
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  height: 4.15rem;
  padding-top: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    width: 92.5%;
    padding-bottom: 0.4rem;
  }
`

/**Extreme parts**/
export const ExtremeParts = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(props) => {
    if (props.$side === 'left') return 'flex-start';
    if (props.$side === 'right') return 'flex-end';
  }};
`

export const ProductFinderBlock = styled.div``

export const AddressChooserBlock = styled.div`
  margin-left: 1rem;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    margin-left: 1.5rem;
  }
`

export const CartBlock = styled.div`
  margin-right: 1rem;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    margin-right: 1.5rem;
  }
`

export const ProfileBlock = styled.div``


/**Center part**/
export const CenterPart = styled.div`
  height: 100%;
  flex-basis: 0;
  flex-grow: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainLogo = styled(Link)`
  display: block;
  background: url(${Logos.FullLogoWaterSite}) no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    width: 5rem;
    background: url(${Logos.ShortLogoWaterSite}) no-repeat center;
  }
`

/**Burger menu**/
export const IconSquare = styled.div`
  position: relative;
  display: none;
  min-height: 3rem;
  aspect-ratio: 1/1;
  background: linear-gradient(white 0 0) padding-box, linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  border: 0.1rem solid transparent;
  border-radius: 50%;
  justify-content: center;
  transition: 0.25s linear;
  transition-delay: 0.025s;

  &:hover {
    box-shadow: 0 0.04rem 0.25rem #cacaca;
  }

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:after{
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 1;
      border-radius: 50%;
      cursor: pointer;
    }
  }
`

export const AdditionalBottom = styled(motion.div)`
  display: none;
  box-sizing: border-box;
  position: absolute;
  margin-top: -0.1rem;
  height: 2rem;
  width: 30%;
  left: 35%;
  z-index: -3;
  background: linear-gradient(white 0 0) padding-box, linear-gradient(to bottom, var(--BLUE_1), var(--BLUE_LIGHT)) border-box;
  border: 0.1rem solid transparent;
  border-radius: 0 0 1.2rem 1.2rem;
  padding-left: 1rem;
  box-shadow: 0 0.04rem 0.4rem #cacaca;
  justify-content: space-between;
  overflow: hidden;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    display: flex;
  }

  /*300%*/
  @media (max-width: 700px) {
    height: 3rem;
    width: 60%;
    left: 20%;
  }
`

export const FindProductInput = styled.input`
  width: 80%;
  height: 100%;
  margin-right: 1rem;

  /*300%*/
  @media (max-width: 700px) {
    width: 85%;
  }
`

export const FindProductButton = styled.div`
  width: 20%;
  height: 100%;
  background: linear-gradient(to bottom, var(--BLUE_1), var(--BLUE_LIGHT)) border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  
  &:hover{
    filter: brightness(95%);
  }

  /*300%*/
  @media (max-width: 700px) {
    width: 15%;
  }
`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`