import styled from 'styled-components';
import SliderImages from "@values/slider_images";
import {WithoutUserPointers} from "@core/css/css_layouts";



export const MainPageSlider = styled.div`
  width: 100%;
  margin-top: 0.65rem;
  overflow: hidden;
  border-top: 0.1rem solid var(--BLUE_DARK);
  border-bottom: 0.1rem solid var(--BLUE_DARK);
  
  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    border-top: 0;
    margin-top: 5rem;
  }
`

export const SliderBlock = styled.div`
  position: relative;
  width: 100vw;
  height: 17.5rem;

  /**Pad**/
  /*175%*/
  @media (max-width: 1200px) {
    height: 18.5rem;
  }
`

export const SliderImage = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => `url(${props.$src_pc})`} no-repeat center;
  background-size: cover;
  ${WithoutUserPointers};

  /*500%*/
  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
    background: ${props => `url(${props.$src_mobile})`} no-repeat center;
    background-size: cover;
  }
`

export const SliderTextBlock = styled.div`
  position: absolute;
  width: 45%;
  left: 45%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  /*300%*/
  @media (max-width: 700px) {
    left: 37.5%;
    width: 55%;
  }
`

export const SliderTextHeader = styled.div`
  text-transform: uppercase;
  font-size: 1.65rem;
  font-weight: 600;
  ${WithoutUserPointers};
`

export const SliderTextInfo = styled.div`
  font-size: 1.1rem;
  padding-top: 1rem;
  ${WithoutUserPointers};
`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`