import React, {useRef} from 'react';

/*Styles*/
import * as S from './styles/cart_button_styles';
import {Link} from "react-router-dom";

/*Created components*/
import useHoverAction from "@current/main/functions/stylistic/useHoverAction";
import {
    IconSquare
} from "@components/water_site_base/base_header/header_logo_and_buttons_line/styles/header_logo_and_buttons_line_styles";
import {CartIconMobile} from "./styles/cart_button_styles";




const CartButton = () => {
    /**Change Icon color**/
    const wrapperIconBlockRef = useRef(null)
    const isReadyToChange = useHoverAction(wrapperIconBlockRef, false);

    return (
        <>
            <S.CartButton as={Link} to={'/cart'} ref={wrapperIconBlockRef}>
                <S.CartIcon $isReadyToChange={isReadyToChange}/>
                <S.Text $isReadyToChange={isReadyToChange}>Корзина</S.Text>
            </S.CartButton>

            <IconSquare as={Link} to={'/cart'}>
                <S.CartIconMobile />
            </IconSquare>
        </>
    );
};

export default CartButton;