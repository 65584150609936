import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const VisualizationBlock = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  ${WithoutBlueHighlighting};
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ModelWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  height: auto;
  width: 90%;
`

