import React, {useEffect} from 'react';

/*Styles*/
import * as S from './styles/header_logo_and_buttons_line_styles';

/*Created components*/


const HeaderLogoAndButtonsLine = () => {

    return (
        <S.GeneralWrapper>
            <S.Wrapper>

                <S.CenterPart>
                    <S.MainLogo to={"/"}/>
                </S.CenterPart>

            </S.Wrapper>
        </S.GeneralWrapper>
    );
};

export default HeaderLogoAndButtonsLine;
