import styled, {keyframes} from "styled-components";
import {
  DefaultButton__Text,
  DefaultButton__Wrapper
} from "@core/components/form_slider_components/general_popup/page_components/styles/default_button";
import {motion} from "framer-motion";
import {WithoutUserPointers} from "@core/css/css_layouts";



/**page_wrapper.js**/
export const PageWrapper = styled.div`
  padding: 1rem 0;
  height: auto;
  width: 100%;
`;

/*Header*/
export const PageHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageHeader__Text = styled.div`
  ${WithoutUserPointers};
  font-size: 1em;
  font-weight: 600;
  position: relative;
  margin-bottom: 0.4em;

  &::after {
    content: "";
    position: absolute;
    top: 1.3em;
    left: -2.5%;
    border-radius: 1em;
    width: 105%;
    height: 0.1em;
    background: linear-gradient(to right, var(--STANDARD), var(--STANDARD));
  }
`;

/*Button wrapper*/
export const PageButtons__Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: ${(props) => {
  switch (props.$margin) {
    case 'big':
      return '1.75em';
    case 'extraBig':
      return '2.25em';
    default:
      return '1em';
      }
  }};
`;

/*Button*/
export const PageButton = styled.div`
  position: relative;
  ${DefaultButton__Wrapper};
  padding: 0.2em 0.5em;
  border: 0.125em solid transparent;
  margin: 0 0.225em;
`;

export const PageButton__Text = styled.div`
  ${DefaultButton__Text};
  opacity: ${props => props.$loading ? '0%' : '100%'};
`;

/*Button loader*/
export const PageButton__LoaderBlock = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
`

const l3 = keyframes`
 to{transform: rotate(1turn)}
`;

export const PageButton__LoaderIcon = styled.div`
  width: 0.55em;
  padding: 0.25em;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--STANDARD);
  opacity: 50%;
  --_m:
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: ${l3} 1s infinite linear;
`;

/*Text*/
export const PageText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 0.65rem;
`

export const PageText__Header = styled.div`
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.25rem;
  padding: 0 0.25rem;
`

export const PageText__Text= styled.div`
  text-align: center;
  font-size: 0.98rem;
  padding: 0 0.25rem;
`