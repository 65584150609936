/*React*/
import React, {useContext} from 'react';

/*Created components*/
import FollowForm from "@core/components/form_slider_components/follow_pop-up/follow_form";
import Slider from "@core/components/slider/slider";
import {
    PopupExternalContext
} from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";



const FollowPopup = () => {
    const {isStartFirstPos} = useContext(PopupExternalContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    return (
        <Slider {...SliderSettings}>
            <Slider.Slide>
                <FollowForm />
            </Slider.Slide>
        </Slider>
    );
};

export default FollowPopup;
