/*React*/
import React, {Children, cloneElement} from 'react';

/*Styles*/
import * as S from '@core/components/slider/styles/slider_styles';




/**Slides base states**/
export const initialStates = {
    /*Main*/
    slides: [],
    childrenCount: 0,
    clonesCount: {},
    width: 0,
    sliderPosition: null,
    transition: 0,

    /*Addition*/
    blockerDisplay: 'none',
    arrowIsVisible: {left: true, right: true},

    /*Switcher*/
    switchers: [],
    indexSwitch: 1,

    /*Dragging*/
    clickForDragging: false,
    moveForDragging: false,
    offset: 0,
    draggingOffset: 0,
    draggingOffsetDifference: 0,
    slickPX: 0,

    /*AutoPlay*/
    TimeToSlideMS: 20000
};

/*Usual const*/
const directions = {
    LEFT: 1,
    RIGHT: (-1),
};


function reducer(state, action) {
    /**Setting const**/
    /*Clones settings*/
    const infiniteClonesCountHead = 1;
    const infiniteClonesCountTail = 1;
    const standardClonesCountHead = 0;
    const standardClonesCountTail = 0;

    const sliderDirection = directions.RIGHT;

    /**Auxiliary functions**/
    function calcSliderPosition(newSliderPosition = state.sliderPosition) {
        return (sliderDirection * state.width * newSliderPosition);
    }

    function calcMaxOffset() {
        return (sliderDirection * state.width * (state.clonesCount.head + state.childrenCount + state.clonesCount.tail - 1));
    }

    /**Reducer logic**/
    switch (action.type) {
        case 'SET_SLIDES_INFINITY':
            return {
                ...state, slides: [
                    cloneElement(action.payload.childrenElements[Children.count(action.payload.childrenElements) - 1], {key: 'head_slide'}),
                    ...action.payload.childrenElements,
                    cloneElement(action.payload.childrenElements[0], {key: 'tail_slide'}),
                ],
                childrenCount: Children.count(action.payload.childrenElements),
                clonesCount: {head: infiniteClonesCountHead, tail: infiniteClonesCountTail},
                sliderPosition: state.sliderPosition + infiniteClonesCountHead,
            };
        case 'SET_SLIDES_STANDARD':
            return {
                ...state, slides: [
                    action.payload.childrenElements,
                ],
                childrenCount: Children.count(action.payload.childrenElements),
                clonesCount: {head: standardClonesCountHead, tail: standardClonesCountTail},
                sliderPosition: state.sliderPosition + standardClonesCountHead,
            };
        case 'HIDE_ARROWS':
            return {
                ...state,
                arrowIsVisible: {left: action.payload.hideMarkers.left, right: action.payload.hideMarkers.right},
            };
        case 'RESIZE_SLIDER':
            return {
                ...state,
                ...state,
                width: action.payload.windowWidth,
            };
        case 'SET_SLICK_IN_PX':
            return {
                ...state,
                slickPX: ((action.payload.windowWidth) / 100 * action.payload.slickPercent),
            };
        case 'SET_SLIDER_POSITION':
            return {
                ...state,
                transition: action.payload?.transition,
                offset: calcSliderPosition(action.payload?.newPosition),
            };
        case 'INFINITY_START':
            return {
                ...state,
                transition: 0,
                sliderPosition: state.clonesCount.head,
            };
        case 'INFINITY_END':
            return {
                ...state,
                transition: 0,
                sliderPosition: action.payload.childrenCount,
            };
        case 'SLIDE_RIGHT':
            if (calcSliderPosition() > calcMaxOffset()) {
                return {
                    ...state,
                    transition: action.payload.transition ? action.payload.transition: 500,
                    sliderPosition: state.sliderPosition + 1,
                    indexSwitch: state.indexSwitch + 1,
                    blockerDisplay: action.payload.blockDisplay ? 'block' : 'none',
                };
            } else {
                return state;
            }
        case 'SLIDE_LEFT':
            if (calcSliderPosition() < 0) {
                return {
                    ...state,
                    transition: action.payload.transition,
                    sliderPosition: state.sliderPosition - 1,
                    indexSwitch: state.indexSwitch - 1,
                    blockerDisplay: action.payload.blockDisplay ? 'block' : 'none',
                };
            } else {
                return state;
            }
        case 'SLIDE_ON_START_PAGE':
            return {
                ...state, sliderPosition: state.clonesCount.head,
            }
        case 'OFF_DISPLAY_BLOCKER':
            return {
                ...state, blockerDisplay: 'none',
            };
        case 'RELOAD_SWITCHERS':
            return {
                ...state, switchers: [Array.from({length: action.payload.childrenCount}, (_, i) => (
                    <S.Slider__SwitcherButtonClickArea key={`button_area_${i + 1}`}
                                                       onClick={() => action.payload.switchFunc(i + 1)}>
                        <S.Slider__SwitcherButton key={`button_num_${i + 1}`} $active={i + 1 === state.indexSwitch} />
                    </S.Slider__SwitcherButtonClickArea>
                ))],
            };
        case 'SWITCH_SLIDE':
            return {
                ...state,
                blockerDisplay: 'none',
                transition: action.payload.transition,
                sliderPosition: (state.clonesCount.head + action.payload.slideKey - 1),
                indexSwitch: action.payload.slideKey,
            };
        case 'SET_SWITCHER_COLOR':
            if (action.payload.newActiveIndexSwitch !== undefined) {
                return {
                    ...state, indexSwitch: action.payload.newActiveIndexSwitch,
                };
            } else {
                return state;
            }
        /*For dragged slider*/
        case 'RETURN_PREV_POSITION':
            return {
                ...state, transition: 500, offset: calcSliderPosition(),
            };
        case 'START_DRAGGING':
            return {
                ...state,
                clickForDragging: true,
                transition: 0,
            };
        case 'DRAGGING':
            return {
                ...state,
                moveForDragging: true,
                draggingOffset: action.payload.newOffset,
                offset: action.payload.newOffset,
                draggingOffsetDifference: (Math.abs(action.payload.newOffset) - Math.abs(calcSliderPosition())),
            };
        case 'END_DRAGGING':
            return {
                ...state,
                clickForDragging: false,
                moveForDragging: false,
            };
        case 'OUT_DRAGGING':
            return {
                ...state,
                clickForDragging: false,
                moveForDragging: false,
            };
        default:
            return state;
    }
}


export default reducer;
