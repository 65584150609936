import {createSlice} from '@reduxjs/toolkit';



const userInfoSlice = createSlice({
    name: 'userInfoSlice',
    initialState: {
        city: "",
        okPopupText: ""
    },
    reducers: {
        setCity: (state, action) => {
            state.city = action.payload.text;
        }
    },
});

export const {setCity: UserInfoSetCity} = userInfoSlice.actions;

export default userInfoSlice.reducer;
