import React, {useEffect} from 'react';

/*Created components*/
import MainPageSlider from "@components/water_site_base/main_page_slider/main_page_slider";
import About from "@components/water_site_base/about/about";
import ProductSlider from "@components/water_site_base/product_slider/product_slider";
import TemplateSlider from "@components/water_site_base/template_slider/template_slider";
import ExamplesForMainPage from "@components/water_site_base/examples_for_main_page/examples_for_main_page";
import ConsultationBlock from "@components/water_site_base/consultation_block/consultation_block";
import ReferenceAndHelpSlider from "@components/water_site_base/reference_and_help_slider/reference_and_help_slider";
import NewsSlider from "@components/water_site_base/news_slider/news_slider";
import ArticlesSlider from "@components/water_site_base/articles_slider/articles_slider";
import ApiRequest from "@core/api/api_request";



export async function mainPageLoader() {
    const mainPageItems = await ApiRequest("get", `api/loaders/main_page/`);
    return {mainPageItems};
}

const MainPage = () => {
    return (
        <>
            <MainPageSlider/>
            <About/>
            <ProductSlider/>
            <TemplateSlider/>
            <ExamplesForMainPage/>
            <ConsultationBlock/>
            <ReferenceAndHelpSlider/>
            <NewsSlider/>
            <ArticlesSlider/>
        </>
    );
};

export default MainPage;
