import styled, {css} from 'styled-components';
import {WithoutBlueHighlighting, WithoutUserPointers} from "@core/css/css_layouts";
import Icons from "@values/icons";
import {motion} from "framer-motion";


export const CitySelector = styled.div`
  position: relative;
  border-radius: 0.2rem 0.2rem 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  
  &:hover {
    box-shadow: 0 0.04rem 0.25rem #cacaca;
  }
  
  /*175%*/
  @media (max-width: 1200px) {
    display: none;
  }
`

export const MainBlock = styled(motion.div)`
  height: 1.35rem;
  border-radius: 0.2rem 0.2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: white;
  overflow: hidden;
  z-index: 3;
  
  ${(props) =>
          props.$expand &&
          css`
            box-shadow: 0 0.04rem 0.25rem #cacaca;
          `
  }
`

export const CityDummy = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  padding-right: 0.35rem;
  padding-left: 0.45rem;
  ${WithoutUserPointers};
`

export const Arrow = styled.div`
  background: url(${Icons.FilledArrow}) no-repeat center;
  background-size: contain;
  padding-right: 0.7rem;
  margin-top: 0.05rem;
  min-width: 0.75rem;
  min-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s all ease;
  transform: ${props => props.$expand ? 'rotate(90deg)' : 'rotate(-90deg)'};;
`

/**Choose city block*/
export const ChoseCityBlock = styled(motion.div)`
  position: absolute;
  width: 100%;
  max-height: 8rem;
  overflow: auto;
  top: 100%;
  z-index: 2;
  box-shadow: 0 0.04rem 0.25rem #cacaca;
  ${WithoutBlueHighlighting};

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }

  &::-webkit-scrollbar-track {
    opacity: 1;
    background: var(--BLACK_4);
  }
`


export const InsideCityBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 0.2rem 0.2rem;
  overflow: hidden;
  background: white;
`

export const CityBlockHeader = styled.div`
  font-size: 0.65rem;
  font-weight: 500;
  height: 1rem;
  display: flex;
  padding: 0.1rem 0;
  justify-content: center;
  align-items: center;
  min-width: 9rem;
  white-space: nowrap;
  background: var(--BLACK_5);
`

export const ChooseCityBlock = styled.div`
  font-size: 0.7rem;
  height: 1rem;
  display: flex;
  padding: 0.1rem 0;
  justify-content: center;
  align-items: center;
  min-width: 9rem;
  white-space: nowrap;
  cursor: pointer;
  
  &:hover{
    background: var(--BLUE_2);
  }
`

export const MagnifierIcon = styled.div`
  background: url(${Icons.MagnifierWS}) no-repeat center;
  background-size: contain;
  height: 1.15rem;
  width: 1.15rem;
  padding: 0.25rem;
`