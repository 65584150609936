import styled from 'styled-components';



export const ExamplesForMainPage = styled.div`
  width: 100vw;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 77.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const ExamplesWrapper = styled.div`
  margin: 0.5rem 0.8rem 1.2rem 0.8rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`