import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/base_header_styles';

/*Created components*
/*import AdditionalMenu from "@components/water_site_base/header_additional_menu/header_additional_menu";*/
import HeaderLogoAndButtonsLine
    from "@components/water_site_base/base_header/header_logo_and_buttons_line/header_logo_and_buttons_line";
import Navigation from "@components/water_site_base/base_header/navigation/navigation";
import MobileNavigation from "@components/water_site_base/base_header/mobile_navigation/mobile_navigation";
import {useSelector} from "react-redux";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import AskPopUp from "@core/components/form_slider_components/ask_popup/ask_pop-up";
import {useLoaderData} from "react-router-dom";



const BaseHeader = () => {
    const showed = useSelector((state) => state.MobileNavigationReducer.menuShowed);
    const [isExpand, setIsExpand] = useState(false);

    /*upgrade*/
    useEffect(() => {
        if (showed) {
            setIsExpand(true);
        } else {
            const timer = setTimeout(() => {
                setIsExpand(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [showed]);

    /**Ask popup**/
    const [isExistPopupAsk, setIsExistPopupAsk] = useState(false);
    const popupStatesAsk = {isExist: isExistPopupAsk, setExist: setIsExistPopupAsk};

    return (
        <>
            <GeneralPopup {...popupStatesAsk}>
                <AskPopUp/>
            </GeneralPopup>

            <S.BaseHeader $menuSize={isExpand} id='top'>
                {/*<AdditionalMenu/>*/}

                <HeaderLogoAndButtonsLine/>
                <Navigation isExistPopupAsk={isExistPopupAsk} setIsExistPopupAsk={setIsExistPopupAsk}/>

                <MobileNavigation isExistPopupAsk={isExistPopupAsk} setIsExistPopupAsk={setIsExistPopupAsk}/>
            </S.BaseHeader>
        </>
    );
};

export default BaseHeader;