import { useState, useEffect, useRef } from 'react';



const useClickOutside = (initialState = true) => {
    const [isDefault, setIsDefault] = useState(initialState);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setIsDefault(true);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return { isDefault, setIsDefault, wrapperRef };
};

export default useClickOutside;