import styled from 'styled-components';
import {WithoutBlueHighlighting, WithoutUserPointers} from "@core/css/css_layouts";



export const TemplateButtonBlock = styled.div`
  margin: 0.5rem 0.5rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.4rem;
  cursor: pointer;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  color: black;
  ${WithoutBlueHighlighting};

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0.04rem 0.4rem #cacaca;
  }
`

export const TemplateImage = styled.img`
  object-fit: cover;
  width: 12rem;
  height: 7.5rem;
  border-radius: 0.4rem;

  /*175%*/
  @media (max-width: 1200px) {
    width: 10.5rem;
    height: 6.5rem;
  }
  
  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    width: 10rem;
    height: 6rem;
  }
`

export const TemplateName = styled.div`
  margin-top: 0.25rem;
  font-size: 1.05rem;
  ${WithoutUserPointers};
`