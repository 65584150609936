import React, {useContext, useState} from 'react';

/*Styles*/
import * as S from './styles/model_load_popup_styles';

/*Created components*/
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import FileInput from "@core/components/form_slider_components/general_popup/input_components/file_input/file_input";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import useFormData from "@core/hooks/useFormData";
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {useDispatch} from "react-redux";
import SliderContext from "@core/components/slider/context";
import ModelLoadContext from "@components/water_site_base/model_load_popup/model_load_context";
import PopupInsideContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import FrontErrorsContext from "@core/contexts/front_errors_context";



const LoadImagesForm = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {subproduct, setIsChooseModel} = useContext(ModelLoadContext)
    const {activePageName, setActivePageName} = useContext(PopupInsideContext);

    const slideToChooseModel = () => {
        setActivePageName('ChooseModelForm')
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**Front errors**/
    const {closePopupFunc} = useContext(PopupExternalContext);
    const dispatch = useDispatch();
    const [frontErrors, setFrontErrors] = useState(null)

    const nextFunction = () =>{
        setFrontErrors({'file_1': ["Ошибка"]})
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Загрузка файлов 3D модели</PageHeader>

                <FrontErrorsContext.Provider value={{frontErrors, setFrontErrors}}>
                    <InputsForm isTooltip={true} tooltipInputName={"files_form"} nextFunction={nextFunction}>
                        <AnimatePresence>
                            {activePageName === 'LoadImagesForm' && (
                                <MotionDiv initial={'usualInitial'}
                                           animate='animate'
                                           exit='exit'
                                           variants={slideUpDown()}>

                                    <FileInput name={"file_1"} isExplanation={true}/>

                                    <FileInput name={"file_2"} isExplanation={true}/>
                                </MotionDiv>
                            )}
                        </AnimatePresence>

                        <PageButtons__Wrapper margin={'big'}>
                            <PageButton onClick={slideToChooseModel}>Назад</PageButton>
                            <PageButton>Загрузить</PageButton>
                        </PageButtons__Wrapper>
                    </InputsForm>
                </FrontErrorsContext.Provider>
            </PageWrapper>
        </MotionDiv>
    );
};

export default LoadImagesForm;