import styled from 'styled-components';
import Icons from "@values/icons";
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const ProductFinder = styled.div`
  font-size: 1rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  background:
          linear-gradient(white 0 0) padding-box,
          linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  border:  0.1rem solid transparent;
  border-radius: 1.2rem;
  overflow: hidden;

  /*175%*/
  @media (max-width: 1200px) {
    display: none;
  }
`

export const LeftPart = styled.div`
  width: ${props => props.$isExpanded ? '8.5em' : '5.9em'};
  height: 100%;
  padding-left: ${props => props.$isExpanded ? '0.7em' : '0.9em'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: text;
  border-right: 0.1rem solid;
  border-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK)) 0 100%;
  transition: 0.25s all ease;
`

export const FinderInput = styled.input.attrs({type: "text", maxLength: "25"})`
  height: 100%;
  width: 100%;
  padding-right: 0.7em;
  font-size: 0.65em;
  font-weight: 500;
  overflow: hidden;
  ${WithoutBlueHighlighting};
`

export const RightPart = styled.div`
  position: relative;
  width: 1.35em;
  height: 100%;
  padding-right: 0.2em;
  padding-left: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
    opacity: 0;
    transition: 0.25s all ease;
    transition-delay: 0.025s;
  }

  &:hover::before{
    position: absolute;
    opacity: 1;
  }
`

export const FinderButton = styled.div`
  width: 100%;
  height: 100%;
  background: url(${Icons.Magnifier}) no-repeat center;
  background-size: 75% 75%;
  margin-right: 0.25em;
  filter: ${(props) => (props.$isReadyToChange ? 'brightness(2)': 'brightness(1)')};
  transition: 0.25s all ease;
  transition-delay: 0.025s;
`

/**Burger**/
export const BurgerIcon = styled.div`
  height: 2rem;
  width: 2rem;
  padding: 0.25rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  
  /*175%*/
  @media (max-width: 1200px) {
    display: block;
  }
`