import React from 'react';

/*Styles*/
import * as S from './styles/base_footer_styles';

/*Created components*/



const BaseFooter = () => {
    return (
        <S.BaseFooter>
            <S.Wrapper>
                <S.WrapperForPart>
                    <S.ContactsBlock>
                        <S.ContactsHeader>Контакты</S.ContactsHeader>
                        <S.ContactsPhone>+7 (982) 913 93 92</S.ContactsPhone>
                        <S.ContactsEmail>waterprint@list.ru</S.ContactsEmail>
                        <S.SocialsBlock>
                            <S.SocialIconBlock/>
                            <S.SocialIconBlock/>
                            <S.SocialIconBlock/>
                        </S.SocialsBlock>
                    </S.ContactsBlock>
                </S.WrapperForPart>



                <S.FasieBlock>
                    <S.FasieHeader>При поддержке:</S.FasieHeader>
                    <S.FasieIcon/>
                    <S.FasieSeparator/>
                    <S.PutpIcon/>
                </S.FasieBlock>



                <S.WrapperForPart>
                    <S.FollowBlock>
                        <S.FollowHeader>Получай уведомления<br/>о новостях платформы</S.FollowHeader>
                        <S.FollowButton>Подписаться</S.FollowButton>
                    </S.FollowBlock>
                </S.WrapperForPart>
            </S.Wrapper>

            <S.CompanyInfo>
                Политика ООО «Интернет Решения»
                (ОГРН 1027739244741, ИНН 7704217370, 123112,
                Россия, г. Москва, Пресненская наб.,
                д. 10, эт. 41, пом. I, ком. 6)
            </S.CompanyInfo>

            <S.AdditionalUrlsBlock>
                <S.AdditionalUrlItem>Оплата и доставка</S.AdditionalUrlItem>
                <S.AdditionalUrlItem>Публичная оферта</S.AdditionalUrlItem>
            </S.AdditionalUrlsBlock>

            <S.VersionBlock>
                <S.VersionItem>Версия: 0.0.4</S.VersionItem>
                <S.VersionItem>WATER-SITE.RU 2024</S.VersionItem>
                <S.VersionItem></S.VersionItem>
            </S.VersionBlock>
        </S.BaseFooter>
    );
};

export default BaseFooter;
