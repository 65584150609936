import styled from 'styled-components';
import {WithoutUserPointers} from "@core/css/css_layouts";



export const VideoBlock__Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const Video = styled.video`
  height: 100%;
  ${WithoutUserPointers};
`


