import Magnifier from '@assets/icons/magnifier_ws.svg';
import MagnifierWS from '@assets/icons/magnifier_ws.svg';
import FilledArrow from '@assets/icons/filled_arrow_ws.svg'
import CartInSquare from '@assets/icons/cart_in_square.svg'
import ProfileInSquare from '@assets/icons/profile_in_square.svg'
import ThinArrow from '@assets/icons/thin_arrow.svg'
import FASIE from '@assets/images/fasie/fasie.svg'
import PUTP from '@assets/images/fasie/putp.png'
import Hamburger from '@assets/icons/hamburger_lines.svg'
import ProfileInSquareWS from "@assets/icons/profile_in_square_ws.svg"
import CartInSquareWS from "@assets/icons/cart_in_square_ws.svg"
import Update from "@assets/icons/update.svg"
import Rotate from "@assets/icons/rotate.svg"



const Icons = {
    Magnifier,
    MagnifierWS,
    FilledArrow,
    CartInSquare,
    CartInSquareWS,
    ProfileInSquare,
    ProfileInSquareWS,
    ThinArrow,
    FASIE,
    PUTP,
    Hamburger,
    Update,
    Rotate
};

export default Icons;