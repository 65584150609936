import React, { forwardRef } from 'react';
import * as S from './styles/video_block_styles';



const VideoBlock = forwardRef(({ src_video }, ref) => {
    const disableContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <S.VideoBlock__Wrapper>
            <S.Video
                ref={ref}
                src={src_video}
                crossOrigin="anonymous"
                muted
                loop
                autoPlay
                playsInline
                disablePictureInPicture
                disableRemotePlayback
                controls={false}
                className="video_slider"
                preload="auto"
                onContextMenu={disableContextMenu}
            />
        </S.VideoBlock__Wrapper>
    );
});

export default VideoBlock;