import React from 'react';

/*Styles*/
import * as S from './styles/consultation_block_styles';



const ConsultationBlock = () => {
    return (
        <S.ConsultationBlock>
            <S.Header>Нужна дополнительная консультация?</S.Header>
            <S.AdditionalText>Оставьте заявку на звонок нашего менеджера или задайте свой вопрос в онлайн-чате</S.AdditionalText>
            <S.ButtonsBlock>
                <S.Button $text={"Оставить заявку"}></S.Button>
                <S.Button $text={"Оставить заявку"}></S.Button>

            </S.ButtonsBlock>
        </S.ConsultationBlock>
    );
};

export default ConsultationBlock;
