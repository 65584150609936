import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";



export const About = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${WithoutBlueHighlighting};
`

export const Wrapper = styled.div`
  width: 90%;
  max-width: 72.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const AboutUsInfoBlock = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1.5rem;

  /*400%*/
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const AboutUsHeader = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.35rem;
  font-size: 1.275rem;
  font-weight: 600;
`

export const AboutUsTextInfo = styled.div`
  text-align: center;
  font-size: 0.85rem;
  font-weight: 500;

  /*400%*/
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

export const SeparatorLine = styled.div`
  width: 100%;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  height: 0.1rem;
  border-radius: 1.2rem;
  background: var(--BLUE_DARK);
  
`

export const SideLogoBlock = styled.div`
  margin-top: 1.5rem;
  width: 10rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /*400%*/
  @media (max-width: 600px) {
    display: none;
  }
`

export const Logo = styled.div`
  background: ${props => `url(${props.$src})`} no-repeat center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
`

export const InfoAboutStartapp = styled.div`
  display: flex;
  justify-content: center;
`

export const Support = styled.div`
  width: 75%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 0.10rem;
  margin: 2rem 0.07rem 0 0.07rem;
  border-left: 0.2rem solid var(--STANDARD);
  border-right: 0.2rem solid var(--STANDARD);

  /*400%*/
  @media (max-width: 600px) {
    width: 90%;
    font-size: 0.9rem;
  }
`

export const FASIELogo = styled.img`
  margin-top: 1rem;
  width: 6rem;
`

export const FasieBlock = styled.a`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`