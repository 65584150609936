import styled from 'styled-components';
import {WithoutUserPointers} from "@core/css/css_layouts";
import Icons from "@values/icons";



export const Scroll__Wrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 1.5rem;
  bottom: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.15rem;
  z-index: 10;
  opacity: 30%;
  transition: all ease 0.25s;
  border: 0.15rem solid var(--BLACK_3);
  border-radius: 50%;

  &:hover {
    opacity: 80%;
  }

  /*400%*/
  @media (max-width: 600px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const Scroll__Icon = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  background: url(${Icons.ThinArrow}) no-repeat center;
  transform: rotate(90deg);
  filter: invert(55%);
  margin-bottom: 0.025rem;
  ${WithoutUserPointers};

  /*400%*/
  @media (max-width: 600px) {
    width: 2rem;
    height: 2rem;
  }
`;
