import styled from 'styled-components';



export const ProductPage = styled.div`
  margin-top: 0.65rem;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 0.1rem solid var(--BLUE_DARK);
  border-bottom: 0.1rem solid var(--BLUE_DARK);
  padding-bottom: 1.2rem;

  /*175%*/
  @media (max-width: 1200px) {
    margin-top: 5rem;
  }
`

export const a2 = styled.div`

`

export const a3 = styled.div`

`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`