import React from 'react';

/*Styles*/
import * as S from './styles/about_styles';

/*Created components*/
import Logos from "@values/logos";
import Icons from "@values/icons";
import {Link} from "react-router-dom";



const About = () => {
    return (
        <S.About>
            <S.Wrapper>
                <S.InfoAboutStartapp>
                    <S.SideLogoBlock>
                        <S.Logo $src={Logos.ShortLogoWaterSite}/>
                    </S.SideLogoBlock>

                    <S.AboutUsInfoBlock>
                        <S.AboutUsHeader>Чем мы занимаемся?</S.AboutUsHeader>
                        <S.AboutUsTextInfo>
                            Наша платформа представляет из себя набор функций и возможностей, облегчающих
                            процесс взаимодействия с типографиями, а также услугами и продуктами предоставляемых ими
                        </S.AboutUsTextInfo>
                        <S.SeparatorLine/>
                        <S.AboutUsTextInfo>
                            Помимо этого платформа предоставляет возможность пользователю предварительно увидеть
                            результат заказа, загрузив готовые макеты в 3D визуализаор или же создав их на платформе.
                        </S.AboutUsTextInfo>
                    </S.AboutUsInfoBlock>

                    <S.SideLogoBlock>
                        <S.Logo $src={Logos.CT}/>
                    </S.SideLogoBlock>
                </S.InfoAboutStartapp>

                <S.Support>
                    Проект поддержан Фондом содействия инновациям в рамках программы
                    «Студенческий стартап» федерального проекта
                    «Платформа университетского технологического предпринимательства»
                </S.Support>

                <S.FasieBlock href={'https://fasie.ru/'}>
                    <S.FASIELogo src={Icons.FASIE}/>
                </S.FasieBlock>


            </S.Wrapper>
        </S.About>
    );
};

export default About;