import styled from 'styled-components';
import {GradientStandardButtonBold} from "@current/main/stylistic/standard_button";
import {WithoutBlueHighlighting} from "@core/css/css_layouts";


export const ConsultationBlock = styled.div`
  margin-top: 2.5rem;
  width: 100vw;
  height: 11rem;
  border-top: 0.2rem solid var(--BLUE_DARK);
  border-bottom: 0.2rem solid var(--BLUE_LIGHT);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${WithoutBlueHighlighting};
`

export const Header = styled.div`
  font-size: 1.2rem;
  font-weight: 550;

  /*400%*/
  @media (max-width: 600px) {
    font-size: 1.4rem;
  }
`

export const AdditionalText = styled.div`
  margin: 0.5rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  text-align: center;

  /*400%*/
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`

export const ButtonsBlock = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`

export const Button = styled(GradientStandardButtonBold)`
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.25rem 0.45rem;
  margin: 0 0.3rem;
`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`

export const a11 = styled.div`

`

export const a12 = styled.div`

`

export const a13 = styled.div`

`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`