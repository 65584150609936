import React from 'react';
import {Outlet} from "react-router-dom";

/*Styles*/
import * as S from './styles/base_template_startapp_styles';

/*Created components*/
import BaseHeader from "@components_startapp/base_header/base_header";
import BaseFooter from "@components_startapp/base_footer/base_footer";



const BaseTemplateStartapp = () => {
    return (
        <>
            <BaseHeader/>
            <Outlet/>
            <BaseFooter/>
        </>
    );
};

export default BaseTemplateStartapp;