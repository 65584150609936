import styled, { keyframes } from 'styled-components';
import {motion} from 'framer-motion';



export const Loader__Wrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  overflow: hidden;
`;

const animsquare1 = keyframes`
  0%, 5%, 95%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  30%, 70% {
    transform: translate(-1.5em, 0) rotate(-90deg);
  }
`;

const animsquare2 = keyframes`
  0%, 10%, 90%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  35%, 65% {
    transform: translate(-1.5em, 0) rotate(-90deg);
  }
`;

const animsquare3 = keyframes`
  0%, 15%, 85%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  40%, 60% {
    transform: translate(-1.5em, 0) rotate(-90deg);
  }
`;

const animsquare4 = keyframes`
  0%, 20%, 80%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  45%, 55% {
    transform: translate(-1.5em, 0) rotate(-90deg);
  }
`;

export const Loader = styled.div`
  height: 100%;
  width: 100%;

  .l_main {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6.6em;
    height: 4.9em;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 21.1em) {
    transform: scale(0.75);
  }

  @media (max-width: 16.9em) {
    transform: scale(0.5);
  }
`;

export const Square = styled.div`
  position: relative;
  margin-left: ${props => props.index * 1.7}em;

  span {
    position: absolute;
    top: 0;
    left: 0.8em;
    height: 1.4em;
    width: 1.4em;
    border-radius: 0.08em;
    background-color: #FFFFFF;

    &:nth-child(1) {
      top: 0;
      animation-delay: 0.00s;
    }

    &:nth-child(2) {
      top: 1.7em;
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      top: 3.4em;
      animation-delay: 0.30s;
    }
  }

  &:nth-child(1) span {
    animation: ${animsquare1} 2s infinite ease-in;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }

  &:nth-child(2) span {
    animation: ${animsquare2} 2s infinite ease-in;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }

  &:nth-child(3) span {
    animation: ${animsquare3} 2s infinite ease-in;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }

  &:nth-child(4) span {
    animation: ${animsquare4} 2s infinite ease-in;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
  }
`;
