import React, {createContext, useCallback, useContext, useRef, useState} from 'react';
import * as THREE from 'three';
import {OrbitControls} from "three/addons/controls/OrbitControls";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {DRACOLoader} from "three/addons/loaders/DRACOLoader";
import {RectAreaLightHelper} from 'three/addons/helpers/RectAreaLightHelper.js';
import {createWhiteTexture} from "@current/main/functions/3D/additional_models_functions";
import add_base_url from "@core/functions/add_base_url";

const ModelContext = createContext();

export const ModelProvider = ({ children }) => {

    /**Initial args**/
    const [modelArgs, setModelArgs] = useState({
        ModelPath: undefined,
        Z_Value: undefined,
        Roughness: 0,
        DefImage1: undefined,
        DefImage2: undefined,
        ColorStatus: 0,
        AnimationStatus: 0,
        AnimSide: true,
        Delay: 0.005,
        LightArray: [],
        LightHelpers: [],
        Scene: null,
    });

    const [showHelpers, setShowHelpers] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false); // New state to control animation

    const canvasRef = useRef(null);
    const sceneRef = useRef(new THREE.Scene());
    const rendererRef = useRef(null);
    const cameraRef = useRef(null);

    const spinningRef = useRef(true);
    const photoRef = useRef(false);
    let animationFrameId = useRef(null);

    /**Set part of initial args**/
    const setModelArgsFromInstance = (db_model_instance) => {
        setModelArgs((prev) => ({
            ...prev,
            ModelPath: add_base_url(db_model_instance.default_model.model_path),
            Z_Value: db_model_instance.z_value,
            Roughness: db_model_instance.roughness,
            DefImage1: add_base_url(db_model_instance.default_image_1),
            DefImage2: db_model_instance.default_image_2 ? add_base_url(db_model_instance.default_image_2) : undefined,
            ColorStatus: db_model_instance.color,
            AnimationStatus: db_model_instance.model_anim,
        }));
    };

    const clearModelArgs = () => {
        setModelArgs({
            ModelPath: '',
            Z_Value: null,
            Roughness: null,
            DefImage1: '',
            DefImage2: '',
            ColorStatus: '',
            AnimationStatus: '',
        });
    };

    const resetScene = () => {
        // Stop the animation before resetting the scene
        if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
            animationFrameId.current = null;
            setIsAnimating(false);
        }

        sceneRef.current.traverse((object) => {
            if (object.isMesh) {
                object.geometry.dispose();
                if (Array.isArray(object.material)) {
                    object.material.forEach((material) => material.dispose());
                } else if (object.material) {
                    object.material.dispose();
                }
            }
        });

        while (sceneRef.current.children.length > 0) {
            const child = sceneRef.current.children[0];
            sceneRef.current.remove(child);
        }

        // Reset other relevant states
        spinningRef.current = true;
        photoRef.current = false;
    };

    /**Set inside scene args**/
    const createScene = useCallback((canvas, orbit, side_1, side_2) => {
        /**Определение текстур**/
        let default_side1;
        let default_side2;

        /*Если текстуры не переданы, заполняем текстуры стандартным текстурами модели*/
        if(side_1 == null && side_2 == null){
            default_side1 =  modelArgs.DefImage1;
            default_side2 =  modelArgs.DefImage2;
        }

        let result_side_1 = side_1 === undefined ? default_side1 : side_1;
        let result_side_2 = side_2 === undefined ? default_side2 : side_2;

        /**Переменные canvas**/
        canvasRef.current = canvas;
        const width = canvasRef.current.clientWidth;
        const height = canvasRef.current.clientHeight;

        /**Камера**/
        const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 2000);
        camera.position.set(0, 0, modelArgs.Z_Value);
        cameraRef.current = camera;

        /**Orbit**/
        if (orbit){
            const controls = new OrbitControls(camera, canvasRef.current);
            controls.minPolarAngle = Math.PI / 4;
            controls.maxPolarAngle = Math.PI * 3 / 4;
            controls.enablePan = false;
            controls.rotateSpeed = 0.45;
            controls.minDistance= 15;
            controls.maxDistance = 40;
            controls.update()
        }

        /**Рендер**/
        rendererRef.current = new THREE.WebGLRenderer({antialias: true, canvas, alpha: true, preserveDrawingBuffer: true});

        /**Настройки света**/
        /*Эмбиент лайт*/
        const aLight = new THREE.AmbientLight(0x404040)
        aLight.intensity = 1.5;
        aLight.position.set(0,2,0);
        sceneRef.current.add(aLight);

        const Plight1 = new THREE.PointLight(0xFFFFFF, 0.6);
        Plight1.position.set(0, 0, 50);
        sceneRef.current.add(Plight1);
        modelArgs.LightArray.push(Plight1);

        const Plight2 = new THREE.PointLight(0xFFFFFF, 0.6);
        Plight2.position.set(0, 0, -50);
        sceneRef.current.add(Plight2);
        modelArgs.LightArray.push(Plight2);

        const Slight1 = new THREE.SpotLight(0xFFFFFF, 0.3);
        Slight1.angle = 0.5;
        Slight1.position.set(50, 0, 0);
        Slight1.target.position.set(0, 0, 0);
        sceneRef.current.add(Slight1);
        sceneRef.current.add(Slight1.target);
        modelArgs.LightArray.push(Slight1);

        const Slight2 = new THREE.SpotLight(0xFFFFFF, 0.3);
        Slight2.angle = 0.5;
        Slight2.position.set(-50, 0, 0);
        Slight2.target.position.set(0, 0, 0);
        sceneRef.current.add(Slight2);
        sceneRef.current.add(Slight2.target);
        modelArgs.LightArray.push(Slight2);
        /**Конец настроек света**/

        /**Loader**/
        const loadingManager = new THREE.LoadingManager();

        const dLoader = new DRACOLoader();
        dLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
        dLoader.setDecoderConfig({type: 'js'});
        const gltf_loader = new GLTFLoader(loadingManager);
        gltf_loader.setDRACOLoader(dLoader)

        let imageLoader = new THREE.ImageLoader(loadingManager)
        let texture_1 = new THREE.Texture();
        let texture_2 = new THREE.Texture();

        /**Определение текстур**/
        /*Передняя текстура*/
        imageLoader.load(result_side_1, (image) => {
            texture_1.image = image;
            texture_1.needsUpdate = true;
        });

        /*Задняя текстура*/
        if (result_side_2){
            imageLoader.load(result_side_2, (image) => {
                texture_2.image = image;
                texture_2.needsUpdate = true;
            });
        }
        else {
            texture_2 = createWhiteTexture();
        }

        gltf_loader.load(
            modelArgs.ModelPath,
            function (gltfModel) {
                const xOffset = 1;
                const yOffset = 0;

                gltfModel.scene.traverse(function (child) {
                    if (child instanceof THREE.Mesh) {
                        switch (child.material.name) {
                            case 'FrontMaterial':
                            case 'Front':
                                texture_1.center.set(.5, .5);
                                texture_1.wrapS = THREE.MirroredRepeatWrapping;
                                texture_1.wrapT = THREE.MirroredRepeatWrapping;
                                texture_1.rotation = THREE.MathUtils.degToRad(180);
                                texture_1.offset.set(xOffset, yOffset);
                                child.material.roughness = 0.45;
                                child.material.metalness = 0;
                                child.material.map = texture_1;
                                child.material.needsUpdate = true;
                                break
                            case 'BackMaterial':
                            case 'Back':
                                texture_2.center.set(.5, .5);
                                texture_2.wrapS = THREE.MirroredRepeatWrapping;
                                texture_2.wrapT = THREE.MirroredRepeatWrapping;
                                texture_2.rotation = THREE.MathUtils.degToRad(180);
                                texture_2.offset.set(xOffset, yOffset);
                                child.material.roughness = 0.45;
                                child.material.metalness = 0;
                                child.material.map = texture_2;
                                child.material.needsUpdate = true;
                                break
                        }
                    }
                });

                if (modelArgs.AnimationStatus){
                    const model = gltfModel;
                    modelArgs.ModelAnim1 = model.scene.getObjectByName("morphTargetItem");
                    modelArgs.ModelAnim2 = model.scene.getObjectByName("morphTargetItem_1");
                    modelArgs.ModelAnim3 = model.scene.getObjectByName("morphTargetItem_2");
                }

                const model = gltfModel.scene;
                sceneRef.current.add(model);
            },
        );

        startAnimation();

        setModelArgs(prev => ({
            ...prev,
            Scene: sceneRef.current
        }));

    }, [modelArgs.ModelPath]);

    /**Animations**/
    function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        const pixelRatio = window.devicePixelRatio * 5;
        const width = Math.floor( canvas.clientWidth * pixelRatio );
        const height = Math.floor( canvas.clientHeight * pixelRatio );
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    }

    /**FIXME**/
    function checkTicks(anim_coef){
        if (anim_coef > 0 && anim_coef <= 0.6){
            if (modelArgs.AnimSide) {
                modelArgs.Delay -= 0.00003;
            }
            else {
                modelArgs.Delay += 0.00003;
            }
        }
        if (anim_coef < 0 && anim_coef >= -0.5){
            if (modelArgs.AnimSide) {
                modelArgs.Delay += 0.00003;
            }
            else {
                modelArgs.Delay -= 0.00003;
            }
        }
    }

    /**FIXME**/
    function checkAnimSide(anim_coef) {
        if (anim_coef >= 0.6){
            modelArgs.AnimSide = false;
        }
        else if(anim_coef <= -0.5){
            modelArgs.AnimSide = true;
        }
    }

    const animateModel = () => {
        if (resizeRendererToDisplaySize(rendererRef.current)) {
            const canvas = rendererRef.current.domElement;
            cameraRef.current.aspect = canvas.clientWidth / canvas.clientHeight;
            cameraRef.current.updateProjectionMatrix();
        }

        if (spinningRef.current) {
            sceneRef.current.rotation.y += 0.005;
        }

        if (modelArgs.AnimationStatus) {
            /* Анимации */
            if (modelArgs.ModelAnim1) {
                checkAnimSide(modelArgs.ModelAnim1.morphTargetInfluences[0]);
                checkTicks(modelArgs.ModelAnim1.morphTargetInfluences[0]);
                if (modelArgs.AnimSide) {
                    modelArgs.ModelAnim1.morphTargetInfluences[0] += modelArgs.Delay;
                    modelArgs.ModelAnim2.morphTargetInfluences[0] += modelArgs.Delay;
                    modelArgs.ModelAnim3.morphTargetInfluences[0] += modelArgs.Delay;
                } else {
                    modelArgs.ModelAnim1.morphTargetInfluences[0] -= modelArgs.Delay;
                    modelArgs.ModelAnim2.morphTargetInfluences[0] -= modelArgs.Delay;
                    modelArgs.ModelAnim3.morphTargetInfluences[0] -= modelArgs.Delay;
                }
            }
        }

        rendererRef.current.render(sceneRef.current, cameraRef.current);
        if (!photoRef.current) {
            animationFrameId.current = requestAnimationFrame(animateModel);
        }
    };

    const startAnimation = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            animateModel();
        }
    };


    /****Additional control functions****/
    /**Toggle light helpers**/
    const toggleLightHelpers = useCallback(() => {
        if (showHelpers) {
            modelArgs.LightHelpers.forEach(helper => {
                sceneRef.current.remove(helper);
            });

            setModelArgs(prev => ({
                ...prev,
                LightHelpers: [],
            }));
        } else {
            const newHelpers = modelArgs.LightArray.map(light => {
                let actLightHelper;
                if (light.type === "PointLight") {
                    let actSize = 3;
                    actLightHelper = new THREE.PointLightHelper(light, actSize);
                    sceneRef.current.add(actLightHelper);
                } else if (light.type === "SpotLight") {
                    actLightHelper = new THREE.SpotLightHelper(light);
                    actLightHelper.update();
                    sceneRef.current.add(actLightHelper);
                } else if (light.type === "RectAreaLight") {
                    actLightHelper = new RectAreaLightHelper(light);
                    sceneRef.current.add(actLightHelper);
                }
                return actLightHelper;
            });

            setModelArgs(prev => ({
                ...prev,
                LightHelpers: newHelpers,
            }));
        }

        setShowHelpers(prev => !prev);
    }, [showHelpers, modelArgs.LightArray]);

    /**Make photo**/
    const MakePhoto = () =>{
        photoRef.current = true;
        canvasRef.current.toBlob(async (blob) => {
            await SaveBlob(blob, `Фото-${canvasRef.current.width}x${canvasRef.current.height}.png`);
        });
        photoRef.current = false;
    }

    const SaveBlob = (blob, fileName) => {
        return new Promise((resolve, reject)=>{
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
            photoRef.current = false;
            resolve();
        })
    }

    return (
        <ModelContext.Provider value={{modelArgs, setModelArgs, spinningRef, resetScene, MakePhoto, setModelArgsFromInstance, createScene, toggleLightHelpers}}>
            {children}
        </ModelContext.Provider>
    );
};

export const useModel = () => useContext(ModelContext);