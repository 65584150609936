import React from 'react';

/*Created components*/
import BaseHeader from "@components/water_site_base/base_header/base_header";
import BaseFooter from "@components/water_site_base/base_footer/base_footer";
import {Outlet} from "react-router-dom";
import ApiRequest from "@core/api/api_request";



export async function baseData() {
    const baseItems = await ApiRequest("get", `api/loaders/base_data/`);
    return {baseItems};
}

const BaseTemplate = () => {
    return (
        <>
            <BaseHeader/>
            <Outlet/>
            <BaseFooter/>
        </>
    );
};

export default BaseTemplate;