import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/template_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import TemplateButtonBlock from "@components/water_site_base/template_button_block/template_button_block";
import ComponentsSeeMoreButton from "@components/water_site_base/components_see_more_button/components_see_more_button";
import {useLoaderData} from "react-router-dom";



const TemplateSlider = () => {
    const {mainPageItems: mainPageItems} = useLoaderData();
    const [templates, setTemplates] = useState([]);

    useEffect(()=>{
        if (mainPageItems && mainPageItems.data){
            setTemplates(mainPageItems.data.templates_categories);
        }
    },[mainPageItems])


    return (
        <S.TemplateSlider>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Шаблоны"}
                />

                <S.TemplateSliderWrapper>
                    {templates.length > 0 && (
                        templates.map((item) => (
                            <TemplateButtonBlock key={item.id} template={item}/>
                        ))
                    )}
                </S.TemplateSliderWrapper>

                <ComponentsSeeMoreButton/>
            </S.Wrapper>
        </S.TemplateSlider>
    );
};

export default TemplateSlider;