import ProductTest from "@assets/images/test/products/test.jpg"
import TemplateTest from "@assets/images/test/templates/template_test.jpg"
import ExampleTest from  "@assets/images/test/examples/example_test.jpg"
import SocialTest from  "@assets/images/test/vk.svg"
import TypographyTest from "@assets/images/test/typography_test.png"
import ArticleTest from "@assets/images/test/article_test.png"



const Test = {
    ProductTest,
    TemplateTest,
    ExampleTest,
    SocialTest,
    TypographyTest,
    ArticleTest,
};

export default Test;