import React, {useEffect, useRef, useState} from 'react';

/*Styles*/
import * as S from './styles/model_block_styles';

/*Created components*/
import {useModel} from "@current/main/functions/3D/model_provider";


const ModelBlock = ({model}) => {
    const modelCanvasRef = useRef(null);
    const {createScene, modelArgs, setModelArgs, spinningRef, setModelArgsFromInstance, MakePhoto, toggleLightHelpers} = useModel();

    useEffect(()=>{
        setModelArgsFromInstance(model);
    },[model])

    useEffect(() => {
        if (modelArgs.ModelPath && modelArgs.Z_Value !== undefined && modelCanvasRef.current) {
            createScene(modelCanvasRef.current, true);
        }
    }, [modelArgs.ModelPath, modelArgs.Z_Value, createScene]);

    const [spinning, setSpinning] = useState(true)

    const handlerStopAnimations = () =>{
        spinningRef.current = !spinningRef.current;
        setSpinning((prev) => !prev);
    }

    return (
        <S.ModelBlock>
            <S.ModelCanvas ref={modelCanvasRef}/>

            <S.TopButtonsBlock>
                <S.LeftPart>
                    <S.PlayButton $status={spinning} onClick={handlerStopAnimations} />
                </S.LeftPart>

                <S.RightPart>
                    <S.PhotoButton onClick={MakePhoto}/>
                    {/*<S.VideoButton/>*/}
                </S.RightPart>
            </S.TopButtonsBlock>

            <S.FeaturesMenu></S.FeaturesMenu>
        </S.ModelBlock>
    );
};

export default ModelBlock;




