import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/main_page_slider_styles';

/*Created components*/
import Slider from "@core/components/slider/slider";
import VideoBlock from "@core/components/video_block/video_block";
import Video from "@values/startapp/video";



const MainPageSlider = () => {

    const SliderSettings = {
        onArrows: false,
        onSwitcher: false,
        infinite: false,
    };

    return (
        <>
            <S.MainPageSlider id='about'>
                <Slider {...SliderSettings}>
                    <Slider.Slide>
                        <S.SliderBlock>
                            <VideoBlock src_video={Video.VideoVisits}/>

                            <S.SliderTextBlock>
                                <S.SliderTextHeader>
                                    Единое решение <br/> для вашего проекта
                                </S.SliderTextHeader>

                                <S.SliderTextInfo>
                                    Широкий ассортимент продукции и услуг,<br/>
                                    объединяющий несколько типографий<br/>
                                    и множество креативных мастеров.
                                </S.SliderTextInfo>
                            </S.SliderTextBlock>
                        </S.SliderBlock>
                    </Slider.Slide>
                </Slider>
            </S.MainPageSlider>
        </>
    );
};

export default MainPageSlider;