import styled from 'styled-components';



export const ExampleButton = styled.div`
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  height: 8.5rem;
  margin: 0.2rem 0.2rem;
  filter: brightness(85%);
  transition: all ease 0.3s;
  
  &:hover{
    transform: scale(1.01);
    box-shadow: 0 0.08rem 0.4rem #4f4e4e;
    filter: brightness(100%);
  }
`

export const ExampleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`


/**Line breaker**/
export const LineBreaker = styled.div`
  width: 100vw;
  height: 0;
`

export const a4 = styled.div`

`

export const a5 = styled.div`

`

export const a6 = styled.div`

`

export const a7 = styled.div`

`

export const a8 = styled.div`

`

export const a9 = styled.div`

`

export const a10 = styled.div`

`