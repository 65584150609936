import styled from 'styled-components';
import {StandardButtonBold} from "@current/main/stylistic/standard_button";


export const SubproductBlock = styled.div`
  margin-top: 1.2rem;
  height: auto;
  border-radius: 1.6rem;
  box-shadow: 0 0.04rem 0.4rem #cacaca;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
`

export const VisualBlock = styled.div`
  width: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const SubproductImage = styled.img`
  max-width: 90%;
  height: 16rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.04rem 0.2rem #cacaca;
  object-fit: cover;
`

export const Buttons = styled.div`
  margin-top: 0.75rem;
  width: 90%;
  display: flex;
  flex-direction: column;
`

export const TopButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const PinkButton = styled(StandardButtonBold)`
  padding: 0.2rem 1.2rem;
  text-align: center;
  margin: 0 0.25rem;
  border: 0.15rem solid var(--PINK_1);

  &:hover {
    background: var(--PINK_1);
  }
`

export const BottomButtons = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SeeMoreButton = styled(StandardButtonBold)`
    margin: 0 0.25rem;
`


/**CalcBlock**/
export const CalcBlock = styled.div`
  width: 22rem;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const CalcBlockWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const SubproductHeader = styled.div`
  font-size: 1.7rem;
  font-weight: 500;
`

export const SubproductDescription = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
`

export const OptionsBlock = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  height: 100px;
`

export const a14 = styled.div`

`

export const a15 = styled.div`

`

export const a16 = styled.div`

`

export const a17 = styled.div`

`

export const a18 = styled.div`

`

export const a19 = styled.div`

`

export const a20 = styled.div`

`