import React from 'react';

/*Styles*/
import * as S from './styles/template_button_block_styles';
import {Link} from "react-router-dom";
import add_base_url from "@core/functions/add_base_url";



const TemplateButtonBlock = ({template}) => {
    return (
        <S.TemplateButtonBlock as={Link} to={'templates/' + template.slug}>
            <S.TemplateImage src={add_base_url(template.image)}/>
            <S.TemplateName>{template.name}</S.TemplateName>
        </S.TemplateButtonBlock>
    );
};

export default TemplateButtonBlock;