import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/product_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import Other from "@values/startapp/other";
import Products2 from "@assets/startapp/performers_startapp.png";


const ProductSlider = () => {
    return (
        <S.ProductSlider id='products'>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Продукция"}
                />

                <S.ProductsSliderWrapper_1>
                    <S.LeftWrapper>
                        <S.StandardImage src={Other.Products1}></S.StandardImage>
                    </S.LeftWrapper>

                    <S.RightWrapper>
                        <S.TextWrapper>
                            <S.Header>СДЕЛАТЬ ЗАКАЗ СТАЛО ПРОЩЕ</S.Header>
                            <S.SimpleText>Зарегистрированные на платформе типографии и дизайнеры
                                заполняют таблицы связанные с продукцией или услугами которые они
                                предоставляют.
                            </S.SimpleText>
                        </S.TextWrapper>
                    </S.RightWrapper>
                </S.ProductsSliderWrapper_1>

                <S.ProductsSliderWrapper_2>
                    <S.LeftWrapper>
                        <S.TextWrapper>
                            <S.Header>ЭКОНОМЬТЕ ВАШЕ ВРЕМЯ</S.Header>
                            <S.SimpleText>Просто выберите товар или услугу.
                                А после выберите исполнителя, исходя из ваших сроков, бюджета и расположения.
                            </S.SimpleText>
                        </S.TextWrapper>
                    </S.LeftWrapper>

                    <S.RightWrapper>
                        <S.StandardImage src={Other.Products2}></S.StandardImage>
                    </S.RightWrapper>
                </S.ProductsSliderWrapper_2>
            </S.Wrapper>
        </S.ProductSlider>
    );
};

export default ProductSlider;