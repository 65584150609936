import React from 'react';

/*Styles*/
import * as S from './styles/news_button_styles';



const NewsButton = () => {
    return (
        <S.NewsButton>
            <S.Date>14/08/2001</S.Date>
            <S.MainInfoBlock>
                <S.CompanyLogoBlock>
                    <S.CompanyLogo />
                </S.CompanyLogoBlock>

                <S.ArticleText>
                    Уже<br/>
                    на платформе!
                </S.ArticleText>
            </S.MainInfoBlock>

        </S.NewsButton>
    );
};

export default NewsButton;
