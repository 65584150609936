import styled from 'styled-components';
import {WithoutBlueHighlighting} from "@core/css/css_layouts";


export const StandardButtonThin = styled.div`
  border: 0.1rem solid var(--BLUE_DARK);
  padding: 0.1rem 0.3rem;
  border-radius: 0.3rem;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  line-height: 0.8rem;
  cursor: pointer;
  ${WithoutBlueHighlighting};

  &:hover {
    background: var(--BLUE_DARK);
    color: white;
  }
`

export const StandardButtonBold = styled(StandardButtonThin)`
  border: 0.15rem solid var(--BLUE_DARK);
  padding: 0.15rem 0.35rem;
  align-items: center;
  font-weight: 600;
`



export const GradientStandardButtonBold = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(white 0 0) padding-box,
  linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK)) border-box;
  border: 0.15rem solid transparent;
  padding: 0.15rem 0.35rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  transition: 0.25s all ease;
  transition-delay: 0.025s;
  z-index: 2;
  border-radius: 0.3rem;
  cursor: pointer;
  ${WithoutBlueHighlighting};
  
  &:hover{
    color: white;
  }
  
  &::before {
    position: absolute;
    border-radius: 0.15rem;
    content: "";
    inset: 0;
    background-image: linear-gradient(to top, var(--BLUE_LIGHT), var(--BLUE_DARK));
    opacity: 0;
    transition: 0.25s all ease;
    z-index: -1;
  }

  &:hover::before {
    position: absolute;
    opacity: 1;
  }
`

export const ArticlesButton = styled.div`
  margin-top: 0.35rem;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.15rem 0.7rem;
  border-radius: 1.2rem;
  cursor: pointer;
`

export const a5 = styled.div`

`