import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import router from '@entry_point/router/root_router';
import store from '@entry_point/redux/store';
import {Provider, useDispatch, useSelector} from 'react-redux';

/*Styles*/
import GlobalStyle from '@entry_point/global_style';

/*Created components*/
import OkPopup from "@core/components/form_slider_components/ok_popup/ok_popup";
import YesNoPopup from "@core/components/form_slider_components/yes_no_popup/yes_no_popup";
import Loader from "@core/components/pages_loader/pages_loader";
import ScrollTopButton from "@core/components/scroll_top_button/scroll_top_button";
import NotifyMessage from "@core/components/notify_message/notify_message";
import router_startapp from "@entry_point/router/root_router_startapp";
import FollowPopup from "@core/components/form_slider_components/follow_pop-up/follow_popup";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import FollowContext from "@entry_point/initalizers/follow_context";



const Site = () => {
    /****Stylistic****/
    /**Resize-transition**/
    const [disableTransitions, setDisableTransitions] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            /*Animation*/
            setDisableTransitions(true);
            clearTimeout(window.resizeTimeout);
            window.resizeTimeout = setTimeout(() => setDisableTransitions(false), 100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /**End**/


    /**Follow Popup**/
    const [isExistFollowPopup, setIsExistFollowPopup] = useState(false);
    const popupStates = {isExist: isExistFollowPopup, setExist: setIsExistFollowPopup};

    return (
        <>
            <GlobalStyle $disableTransitions={disableTransitions} />
            <NotifyMessage />

            <FollowContext.Provider value={{setIsExistFollowPopup}}>
                <GeneralPopup {...popupStates}>
                    <FollowPopup />
                </GeneralPopup>

                <OkPopup />
                <YesNoPopup />
                <ScrollTopButton />
                <RouterProvider router={router_startapp}/>
            </FollowContext.Provider>
        </>
    );
};


function reactInitiationStartapp() {
    const root = document.getElementById('root');
    const reactRoot = createRoot(root);

    reactRoot.render(
        <Provider store={store}>
            <Loader />
            <Site/>
        </Provider>,
    );
}

export default reactInitiationStartapp;
