import React, {useEffect, useRef} from 'react';

/*Styles*/
import * as S from './styles/header_logo_and_buttons_line_styles';

/*Created components*/
import ProductFinder
    from "@components/water_site_base/base_header/header_logo_and_buttons_line/product_finder/product_finder";
import CitySelector
    from "@components/water_site_base/base_header/header_logo_and_buttons_line/city_selector/city_selector";
import CartButton from "@components/water_site_base/base_header/header_logo_and_buttons_line/cart_button/cart_button";
import ProfileButton
    from "@components/water_site_base/base_header/header_logo_and_buttons_line/profile_button/profile_button";
import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion";
import {hideProductFinder} from "@redux/redux_reducers/mobile_navigation";


const HeaderLogoAndButtonsLine = () => {
    const dispatch = useDispatch();
    const productFinderShowed = useSelector((state) => state.MobileNavigationReducer.productFinderShowed);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                dispatch(hideProductFinder());
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (productFinderShowed) {
            inputRef.current.focus();
        }
    }, [productFinderShowed])

    return (
        <S.GeneralWrapper ref={wrapperRef}>
            <S.HeaderLogoAndButtonsLine>
                <S.Wrapper>
                    <S.ExtremeParts $side={'left'}>
                        <S.ProductFinderBlock>
                            <ProductFinder/>
                        </S.ProductFinderBlock>

                        <S.AddressChooserBlock>
                            <CitySelector/>
                        </S.AddressChooserBlock>
                    </S.ExtremeParts>

                    <S.CenterPart>
                        <S.MainLogo to={"/"}/>
                    </S.CenterPart>

                    <S.ExtremeParts $side={'right'}>
                        <S.CartBlock>
                            <CartButton/>
                        </S.CartBlock>

                        <S.ProfileBlock>
                            <ProfileButton/>
                        </S.ProfileBlock>
                    </S.ExtremeParts>
                </S.Wrapper>
            </S.HeaderLogoAndButtonsLine>

            <AnimatePresence>
                {productFinderShowed &&
                    <S.AdditionalBottom initial={{y: "-100%"}}
                                        animate={{y: "0%"}}
                                        exit={{y: "-100%"}}
                                        transition={{
                                            duration: 0.35
                                        }}>
                        <S.FindProductInput ref={inputRef} placeholder={"Поиск товара"}/>
                        <S.FindProductButton>Ок</S.FindProductButton>
                    </S.AdditionalBottom>
                }
            </AnimatePresence>

        </S.GeneralWrapper>
    );
};

export default HeaderLogoAndButtonsLine;
