import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/product_slider_styles';

/*Created components*/
import ComponentsHeaderBlock from "@components/water_site_base/components_header_block/components_header_block";
import ProductButtonBlock from "@components/water_site_base/product_button_block/product_button_block";
import ComponentsSeeMoreButton from "@components/water_site_base/components_see_more_button/components_see_more_button";
import {useLoaderData} from "react-router-dom";



const ProductSlider = () => {
    const {mainPageItems: mainPageItems} = useLoaderData();
    const [products, setProducts] = useState([]);

    useEffect(()=>{
        if (mainPageItems && mainPageItems.data){
            setProducts(mainPageItems.data.products);
        }
    },[mainPageItems])

    return (
        <S.ProductSlider>
            <S.Wrapper>
                <ComponentsHeaderBlock
                    headerText={"Продукция"}
                />

                <S.ProductsSliderWrapper>
                        {products.length > 0 && (
                            products.map((item) => (
                                <ProductButtonBlock key={item.id} product={item}/>
                            ))
                        )}
                </S.ProductsSliderWrapper>

                <ComponentsSeeMoreButton/>
            </S.Wrapper>
        </S.ProductSlider>
    );
};

export default ProductSlider;