import FullLogoWaterSite from "@assets/logos/full_logo_water_site.svg"
import ShortLogoWaterSite from "@assets/logos/short_logo_water_site.svg"
import CT from "@assets/logos/ct.png"



const Logos = {
    FullLogoWaterSite,
    ShortLogoWaterSite,
    CT
};

export default Logos;