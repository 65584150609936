import React, {useContext, useState} from 'react';

/*Styles*/
import * as S from './styles/model_load_popup_styles';

/*Created components*/
import Slider from "@core/components/slider/slider";
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import ChooseModelForm from "@components/water_site_base/model_load_popup/choose_model_form";
import ModelLoadContext from "@components/water_site_base/model_load_popup/model_load_context";
import LoadImagesForm from "@components/water_site_base/model_load_popup/load_images_form";


const ModelLoadPopup = ({subproduct}) => {
    const {isStartFirstPos} = useContext(PopupExternalContext)
    const {definedPages} = useContext(PopupInsideContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    const [modelData, setModelData] = useState({
        SelectedModel: null,
    })

    return (
        <ModelLoadContext.Provider value={{subproduct, modelData, setModelData}}>
            <Slider {...SliderSettings}>
                <Slider.Slide>
                    <ChooseModelForm/>
                </Slider.Slide>

                <Slider.Slide>
                    <LoadImagesForm/>
                </Slider.Slide>
            </Slider>
        </ModelLoadContext.Provider>
    );
};

export default ModelLoadPopup;