import React, {useContext, useEffect} from 'react';

/*Created components*/
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import useFormData from '@core/hooks/useFormData';
import AuthContext from "@entry_point/initalizers/auth_context";
import {useDispatch} from "react-redux";
import {setTextAndHeaderOkPopup} from "@src/current/entry_point/redux/redux_reducers/ok_popup_slice";
import useRequest from "@core/api/hooks/useRequest";
import ConfirmCodePage
    from "@core/components/form_slider_components/general_popup/full_pages/confirm_code_page/confirm_code_page";



const RegisterConfirmation = () => {
    const dispatch = useDispatch();
    const {closePopupFunc} = useContext(PopupExternalContext);
    /*Extra states*/
    const {setIsAuthenticated} = useContext(AuthContext)

    /**API**/
    /*Confirm page*/
    const parentSuccessFunction = (successData)=>{
        localStorage.setItem('access_token', successData.tokens.access);
        localStorage.setItem('refresh_token', successData.tokens.refresh);
        closePopupFunc();
        setIsAuthenticated(true);
        dispatch(setTextAndHeaderOkPopup({text: "Вы успешно зарегистрированы!", header: ""}))
    }

    const confirmCodePageRegisterProps = {
        pageHeader: "Подтверждение регистрации",
        visibleAllFormPages: ['Register', 'RegisterConfirmation'],
        fromPage: 'Register',
        forPage: "RegisterConfirmation",
        mainRequestUrl: 'api/users/register/final_registration/',
        parentSuccessFunction: parentSuccessFunction
    }

    return (
        <ConfirmCodePage {...confirmCodePageRegisterProps}/>
    );
};

export default RegisterConfirmation;
