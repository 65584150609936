/*Styles*/
import styled from 'styled-components';
import {motion} from 'framer-motion';



/***General***/
/*External wrapper*/
export const GeneralPopup__Wrapper_TypeExternal = styled(motion.div)`
  font-size: 1.2rem;
  border-radius: 0.45rem;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  z-index: 12;
  background: white;
  
  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

/*Inside wrapper*/
export const GeneralPopup__Wrapper_TypeInside = styled.div`
  width: ${props => props.$width  ? props.$width : '25rem'};
  font-family: 'Montserrat', sans-serif;
  overflow: visible;
  /*overflow: ${props => props.$devStatus  ? 'visible' : 'hidden'};*/

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    ${props => props.$width  ? props.$width : '30rem'};
  }
`