import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';



export const toggleProductFinderWithDelay = createAsyncThunk(
    'mobileNavigation/toggleProductFinderWithDelay',
    async (_, { dispatch, getState }) => {
        const { menuShowed } = getState().MobileNavigationReducer;

        if (menuShowed) {
            const hamburger = document.getElementsByClassName('ham')[0]
            hamburger.classList.remove('active');
            dispatch(hideMobileMenu());
            await new Promise(resolve => setTimeout(resolve, 300));
        }

        dispatch(toggleProductFinder());
    }
);

export const openMobileNav = createAsyncThunk(
    'mobileNavigation/toggleProductFinderWithDelay',
    async (_, { dispatch, getState }) => {
        const { productFinderShowed } = getState().MobileNavigationReducer;

        if (productFinderShowed) {
            dispatch(toggleProductFinder());
            await new Promise(resolve => setTimeout(resolve, 300));
        }

        dispatch(showMobileMenu());
    }
);


const mobileNavigationSlice = createSlice({
    name: 'mobileNavigationSlice',
    initialState: {
        menuShowed: false,
        productFinderShowed: false,
    },
    reducers: {
        showMobileMenu: (state) => {
            state.menuShowed = true;

            if (state.productFinderShowed && state.menuShowed) {
                state.productFinderShowed= false;
            }
        },
        hideMobileMenu: (state) => {
            state.menuShowed = false;
        },
        toggleProductFinder: (state) => {
            state.productFinderShowed = !state.productFinderShowed;

            if (state.productFinderShowed && state.menuShowed) {
                state.menuShowed = false;
            }
        },
        hideProductFinder: (state) =>{
            state.productFinderShowed = false;
        }
    },
});

export const {showMobileMenu, hideMobileMenu, toggleProductFinder, hideProductFinder} = mobileNavigationSlice.actions;

export default mobileNavigationSlice.reducer;
