/*Styles*/
import styled, {css} from "styled-components";
import {WithoutUserPointers} from "@core/css/css_layouts";



export const GeneralDefaultInputCSS = css`
  font-weight: 600;
  box-sizing: border-box;
  height: 2.35em;
  margin-top: 0.25em;
  width: 100%;
  outline: 0;
  outline-offset: 0;
  border: 0.18em solid var(--STANDARD);
  font-size: 0.7em;
  padding-left: 0.4em;
  transition: all 0.5s;
`;

export const Input = styled.input`
  ${GeneralDefaultInputCSS};
  ${props => props.$disabled && WithoutUserPointers};
  background: ${props => props.$disabled && '#d7d7d7'};
`;
