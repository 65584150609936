import React from 'react';

/*Styles*/
import * as S from './styles/article_button_styles';



const ArticleButton = () => {
    return (
        <S.ArticleButton>

        </S.ArticleButton>
    );
};

export default ArticleButton;
